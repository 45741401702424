import React from "react";
import "./Table.scss";
import NoResultsMessage from "../shared/prompts/NoResultsMessage";
import LoadingIcon from '../misc/LoadingIcon'

const Table = ({ colWidths, headers, dataMap, id, minWidth, compact, height, restrictHeight, fixedHeaders, dataLoading, noResults, className }) => {
	return (
		<div
			className={`table-wrapper${compact ? " table-compact" : ""}`}
			style={{ height: height ? height : "", maxHeight: restrictHeight ? "calc(50vh - 192px)" : height ? height : "" }}
		>
			{dataLoading ? (
				<LoadingIcon size={48} centered />
			) : dataMap ? (
				<table id={id} className={`table${className ? ' ' + className : ''}`} style={{ minWidth: minWidth }}>
					<colgroup>
						{colWidths.map((width, idx) => (
							<col key={"col-" + idx} span="1" style={{ width }} />
						))}
					</colgroup>
					{headers && (
						<thead className={fixedHeaders ? "fixed-headers" : ""}>
							<tr className="header-table-row">
								{headers.map((header, idx) => {
									return <th key={"header-" + idx}>{header}</th>;
								})}
							</tr>
						</thead>
					)}
					<tbody>{dataMap}</tbody>
				</table>
			) : (
				<NoResultsMessage
					message={noResults && noResults.message ? noResults.message : "No results"}
					subMessage={noResults && noResults.subMessage ? noResults.subMessage : "Looks like there are no results for this page"}
				/>
			)}
		</div>
	);
}

export default Table;
