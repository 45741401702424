import React from 'react'
import useWindowDimensions from '../../utils/Utils.js'
import Popup from './Popup.js'
import "./Tooltip.scss"

const Tooltip = ({ children, offsetY, offsetX, label, placement, display, disabled, delay, className, icon, padding, toggleTooltip, maxWidth, truncate }) => {
	const {width} = useWindowDimensions();
	const mobile = width < 768;
	return (
		<Popup
			className={`${className ? " " + className : ""}${truncate ? " truncate" : ""}`}
			delay={delay}
			hoverOnly={!toggleTooltip}
			disabled={mobile ? true : disabled}
			popupElement={
				<div
					className={`tooltip-popup-wrapper${padding ? " padding-" + padding : ""}${icon ? " tooltip-with-icon" : ""}`}
					style={{ maxWidth: maxWidth ? maxWidth : "", overflowWrap: maxWidth ? "break-word" : "" }}
				>
					{icon && icon}
					{label}
				</div>
			}
			placement={placement ? placement : "top"}
			display={display ? display : "inline-block"}
			offsetY={offsetY}
			offsetX={offsetX}
		>
			{truncate ? <div className="truncate">{children}</div> : children}
		</Popup>
	);
}

export default Tooltip;