import { format, parseISO } from "date-fns";
import { useRef } from "react";

export const getValue = (val, dataSuffix, contentData, publishedDate, pageNum) => {
	if (!val)
		return val;

	

	// if (dataSuffix && this.tickers.length == 1) {
	// 	dataSuffix = null;
	// }

	if (val.value) {
		val = val.value;
	}

	if (!val.includes) {
		return val;
	}

	if (val.includes("PAGE_NUM")/*  || val.includes("Page 2") */) {
		//todo figure out page numbers
		return "Page "+pageNum;
	}

	return val.replace(/\$\{(.*?)\}/g, function(match, quantity) {
		//quantity = quantity.substring
		let errorValue = null;
		if (quantity.startsWith("coalesce")) {
			const end = quantity.indexOf(")");
			errorValue = quantity.substring(9, end);
			quantity = quantity.substring(end+2);
		}
		if (quantity.startsWith("date:")) {
			//TODO format SimpleDateFormat format = new SimpleDateFormat(quantity.substring(5));
			//in editor, this is 'today'.  But in portal, this needs to be the published date.  Which we may or may not have!
			if (publishedDate) {
				try {
				quantity = format(parseISO(publishedDate), quantity.substring(5));
				} catch (e) {
					console.log("Unable to format date ", publishedDate);
				}
			}
		} else {
			const upper = quantity.startsWith("uppercase:");
			if (upper) {
				quantity = quantity.substring(10);
			}
			let dateFormat = null;
			if (quantity.startsWith("formatDate:")) {
				const index = quantity.indexOf(":", 12);
				dateFormat = quantity.substring(11, index);
				quantity = quantity.substring(index + 1);
			}
			let multiplierChar = "1";
			let numberFormat = null;
			if (quantity.startsWith("format:")) {
				multiplierChar = quantity.substring(7, 8);
				const index = quantity.indexOf(":", 9);
				numberFormat = quantity.substring(9, index);
				quantity = quantity.substring(index + 1);
			}
			const bits = new Set();
			quantity = quantity.toUpperCase();
			parseComplexQuantity(quantity, bits);
			let processed = false;
			if (bits.size === 1) {
				bits.forEach(q=>{
					if (q === quantity) {
						//It's a simple
						quantity = contentData[q + (dataSuffix ? dataSuffix : "")];
						if (dateFormat) {
							try {
								quantity = format(parseISO(quantity), dateFormat);
							} catch (e) {
								console.log("unable to parse iso date ", quantity)
							}
						}
						processed = true;
					}
				});
			}
			if (!processed) {
				//Calculation
				let script = "";
				bits.forEach(bit=>{
					let value = contentData[bit + (dataSuffix ? dataSuffix : "")];
					//eslint disabled due to bug bug - see note below
					const sanitisedBit = bit.replace(/[\[\-\]\.]/g, "_");					//eslint-disable-line no-useless-escape
					script += "let " + sanitisedBit + " = " + value + ";"
					
					//Also santise the original
					if (sanitisedBit !== bit) {  //Shouldn't be necessary, but if it is we get an infinite loop so better to be safe than sorry
						while (quantity.indexOf(bit) > -1)
							quantity = quantity.replace(bit, sanitisedBit);
					}
				});
				script += "quantity = " + quantity;
				try {
					eval(script);
				} catch (e) {
					quantity = errorValue || "-";	//unable to evaluate
				}
			}
			if (!quantity || (typeof quantity === "number" && isNaN(quantity))) {
				quantity = "";
			} else {
				if (upper) {
					quantity = quantity.toUpperCase();
				}

				if (dateFormat && !quantity === 'N/A') {
					try {
						quantity = format(parseISO(quantity), dateFormat);
					} catch (e) {
						console.log("unable to parse iso date ", quantity)
					}
				}
				if (numberFormat) {
					switch (multiplierChar.toUpperCase()) {
						case "k":
							quantity /= 1000;
							break;
						case "M":
							quantity /= 1000000;
							break;
						case "B":
							quantity /= 1000000000;
							break;
						default:
							break;
					}
					quantity = new DecimalFormat(numberFormat).format(quantity);  //eslint-disable-line no-undef
					// console.log("Got a number format of ", numberFormat, quantity);
					// switch (numberFormat) {
					// 	case "0":
					// 		quantity = new Intl.NumberFormat('en-NZ', { maximumFractionDigits: 0 }).format(quantity);
					// 		break;
					// 	case "0.0":
					// 		quantity = new Intl.NumberFormat('en-NZ', { maximumFractionDigits: 1 }).format(quantity);
					// 		break;
					// 	case "#,##0":
					// 		quantity = new Intl.NumberFormat('en-NZ', { maximumFractionDigits: 0 }).format(quantity);
					// 		break;
					// 	case "0.00":
					// 		quantity = new Intl.NumberFormat('en-NZ', { maximumFractionDigits: 2 }).format(quantity);
					// 		break;
					// 	default: 
					// 		console.log("Not formatting unknown format of ", numberFormat);
					// }
					// console.log("Final val is ", quantity)
				}
			}
		}
		return quantity;
	});
}

const parseComplexQuantity = (quantity, target) => {
	//NB:  eslint reports no-useless-escape when the escape is _not_ useless.  These regexs are correct :)

	//Firstly remove any - that are part of qualifiers
	quantity = quantity.replace(/(\[[^\]]*?)\-([^\]]*?\])/g, "$1#$2")			//eslint-disable-line no-useless-escape
	//Now split by operators
	const bits = quantity.split(/[\*\+\-\/\(\)\s]+/);							//eslint-disable-line no-useless-escape
	bits.forEach(bit => {
		//Empty?
		if (bit.length === 0)
			return;
		//Number?
		if (bit.match(/^\-?\d*\.?\d*$/))										//eslint-disable-line no-useless-escape
			return;
		//And put back the -s
		bit = bit.replace(/#/, "-");
		const match = bit.match(/\[(\-?\d+)\~(\-?\d+)\]/);						//eslint-disable-line no-useless-escape
		if (match) {
			const start = match[1];
			const end = match[2];
			for (let i=start; i<=end; i++) {
				target.add(bit.replace(match[0], "[" + i + "]"));
			}
		} else {
			target.add(bit);
		}
	});
}


export const getStyle = (style, includeCellStyles)  => {
	const { size, weight, paddingBelowParagraph, height, lineHeight, minHeight, paddingLeft, paddingRight, paddingBottom, width, borderBottomWidth, ...others } = style || {};

	const theStyle = { ...others };

	if (size) {
		theStyle.fontSize = size + "pt";
	}
	if (weight) {
		theStyle.fontWeight = weight;
	}
	if (paddingBelowParagraph) {
		theStyle.marginBottom = paddingBelowParagraph + "pt";
	}
	if (width) {
		theStyle.width = width + "pt";
	}
	if (includeCellStyles) {
		if (minHeight) {
			theStyle.minHeight = minHeight + "pt"
		}
		if (height) {
			theStyle.height = height + "pt";
		}
		if (paddingLeft) {
			theStyle.paddingLeft = paddingLeft + "pt";
		}
		if (paddingRight) {
			theStyle.paddingRight = paddingRight + "pt";
		}
		if (paddingBottom) {
			theStyle.paddingBottom = paddingBottom + "pt";
		}
		if (borderBottomWidth) {
			theStyle.borderBottomWidth = borderBottomWidth+"pt";
		}
		if (lineHeight) {
			theStyle.lineHeight = lineHeight + "pt"
		}
	}
	return theStyle;

}


/**
 * 
 * @param {*} templateStyles The master styles for the template
 * @param {*} componentStyleMap The style mappings for this component
 * @param {*} tableStyles Table styles for this component, if they exist
 * @param {*} data withData callback
 */
 export const getEditableStyles = (templateStyles, componentStyleMap, tableStyles) => {
	const myStyles = Object.keys(componentStyleMap).map(key => {
		const actual = templateStyles[componentStyleMap[key]];
		const converted = getStyle(actual, true);
		converted.lineHeight = "1.2";
		const val = {};
		val[key] = converted;
		return val;
	});

	const flattenedStyles = {};
	myStyles.forEach(style => Object.assign(flattenedStyles, style));

	if (tableStyles) {
		const defaultCellStyle = getStyle(templateStyles[componentStyleMap.cell], true);
		const cellHeaderStyle = getStyle(templateStyles[componentStyleMap.cellHeader], true);
		//browser default cellHeader is text-align: center... kill this
		if (cellHeaderStyle && !cellHeaderStyle.hasOwnProperty("textAlign")) {
			cellHeaderStyle.textAlign = "left";
		}


		const tdStyle = {};
		const thStyle = {};

		const paddingBelowTable = tableStyles.paddingBelowTable;
		if (paddingBelowTable) {
			flattenedStyles.table = {
				marginBottom: paddingBelowTable+"pt"
			}
		} else {
			flattenedStyles.table = {
				marginBottom: tableStyles.cellVerticalPadding + "pt"
			}
		}

		Object.assign(tdStyle, defaultCellStyle);
		flattenedStyles.td = tdStyle;
		Object.assign(thStyle, cellHeaderStyle);
		flattenedStyles.th = thStyle;
	}
	return flattenedStyles;
}

export const getTableStyles = (templateStyles, rowStyles, columnStyles, data) => {
	if (!rowStyles) {
		return;
	}
	const keys = rowStyles.map(obj => obj.key);
	columnStyles.forEach(obj => {
		if (!keys.includes(obj.key)) {
			keys.push(obj.key);
		}
	});

	const myStyles = keys.map(key => {
		const converted = getStyle(templateStyles[key], true);
		const val = {};
		val[key] = converted;
		return val;
	});

	const flattenedStyles = {};
	myStyles.forEach(style => Object.assign(flattenedStyles, style));

	if (flattenedStyles.minHeight) {
		flattenedStyles.minHeight = flattenedStyles.minHeight + 'pt';
	}
	
	return flattenedStyles;
}

const getUniqueId = () => Math.round(Math.random() * 1000000);

export function useComponentId() {
  const idRef = useRef(getUniqueId());
  return idRef.current;
}