import React, { useEffect, useState } from 'react';
import { Plus } from 'react-feather';
import "./TickerSectorImage.scss";
import placeholderImage from "../../resources/images/sectors/research-2.png";
import TickerImage from './TickerImage';

const TickerSectorImage = ({ image, hoverIcon, onClick, size, selected, galleryImage, className }) => {
	const [showHover, setShowHover] = useState(false)
	let imageComponent = null
	useEffect(() => {
		if (imageComponent) {
			setTimeout(() => setShowHover(true), 250)
		}
	}, [imageComponent])

	if (image && image.url) {
		imageComponent = <img src={image.url} alt="ticker/sector logo" />
	}
	if (image && image.ticker) {
		imageComponent = <TickerImage showPlaceholder tickerCode={image.ticker} />
	}
	if (image && !image.ticker && !image.url) {
		imageComponent = <img src={placeholderImage} alt="ticker/sector logo" />
	}

	return (
		<div className={`ts-image-container${size ? " ts-image-size-" + size : ""}${selected ? " selected-image" : ""}${onClick ? ' cursor-pointer' : ''}${className ? " " + className : ""}`} onClick={onClick}>
			{imageComponent}
			{showHover && <div className="ts-image-overlay">{hoverIcon ? hoverIcon : <Plus />}</div>}
		</div>
	)
}

export default TickerSectorImage;