import React, { useEffect, useState } from 'react';
import Button from '../../components/buttons/Button';
import TextInput from '../../components/inputs/TextInput';
import LoginMessageWrapper from '../components/LoginMessageWrapper';
import LoginTextButton from '../components/LoginTextButton';
import { useNavigate } from 'react-router-dom';
import { analytics, useRemote } from '../../utils/Utils';
import LoadingIcon from "../../components/misc/LoadingIcon"
import TwoFactorAuthPage from '../pages/TwoFactorAuthPage'

const ForgotPasswordPage = ({firstTimeUser}) => {
	const [emailSubmitted, setEmailSubmitted] = useState(false)
	const [email, setEmail] = useState("")
	const [submitting, setSubmitting] = useState(false)
	const [twoFARequired, set2FARequired] = useState(false)
	const [code, setCode] = useState("")
	const [error, setError] = useState("")
	 

	const navigate = useNavigate()
	const remote = useRemote()

	useEffect(() => {
		// set2FARequired(true)
		analytics.page()
	}, [])

	const handleEmailSubmit = () => {
		//trigger email to send
		if (twoFARequired) {
			setEmailSubmitted(true)
		} else {
			setSubmitting(true)
			remote.post(firstTimeUser ? "/um/initial-password-setup" : "/um/request-password-reset", { "email": email }).then(() => {
				setEmailSubmitted(true)
				setSubmitting(false)
			}).catch((e) => {
				console.error(e)
				setSubmitting(false)
			});
		}
	}

	const handle2FA = () => {
		// setSubmitting(true)
		// const data = {
		// 	username: username,
		// 	code,
		// 	key: twoFAKey,
		// };

		// remote.post("/um/validate-credentials-2fa", data).then(
		// 	(resp) => {
		// 		if (resp.status === "valid") {
		// 			const data2 = {
		// 				username: username,
		// 				password: password,
		// 				twoFAToken: cookieValue,
		// 				source: "tomato"
		// 			};

						remote.post("/um/request-password-reset", { "email": email }).then(() => {
							setEmailSubmitted(true)
							setSubmitting(false)
						}).catch((e) => {
							console.error(e)
							setSubmitting(false)
						});
		// 			} else {
		// 				setError("Code did not match. Please check it and try again")
		// 			}
		// 		},
		// 	(x) => {
		// 		set2FAError("Oops, that code wasn't right.  Please try again");
		// 	}
		// );
	}

	const onEnterPress = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			if (emailSubmitted && twoFARequired) handle2FA()
			else handleEmailSubmit()
		}
	}

	if (emailSubmitted && twoFARequired) {
		return (
			<div id="forgot-pw-code-page">
				<TwoFactorAuthPage
					onKeyDown={() => handle2FA()}
					value={code}
					onChange={(e) => setCode(e.target.value)}
					onSubmit={() => handle2FA()}
					error={error}
				/>
				<LoginTextButton onClick={() => navigate("/")}>Back to login page</LoginTextButton>
			</div>
		)
	}
	else if (emailSubmitted) {
		return (
			<div id="forgot-pw-page">
				<LoginMessageWrapper header={firstTimeUser ?"First Time User" : "Forgot Password"} message={`We have sent a link to your email address to ${firstTimeUser ? "set" : "reset"} your password. Please follow the link to continue.`} />
			</div>
		);
	}

	return (
		<div id="forgot-pw-page">
			<LoginMessageWrapper header={firstTimeUser ?"First Time User" : "Forgot Password"} message="We’ll send a link to your email address to reset your password." />
			{/* Fields */}
			<TextInput
				className="mb-l"
				label="Email Address"
				placeholder="Jane@example.com"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				onKeyDown={(e) => onEnterPress(e)}
			/>
			<Button
				icon={submitting && <LoadingIcon variant="light" />}
				large
				fullWidth
				disabled={submitting || !email || !(email.length > 5 && email.includes("@") && email.includes("."))}
				className="mb-xl"
				onClick={() => handleEmailSubmit()}
			>
				<div className="font-weight-semi-bold" >{firstTimeUser ? "Send Link" : "Send Recovery Link"}</div>
			</Button>
			<LoginTextButton onClick={() => navigate('/')}>Back to login page</LoginTextButton>
		</div>
	);
}

export default ForgotPasswordPage;