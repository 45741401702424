import React from 'react';
import { Info } from 'react-feather';
import Tooltip from '../popups/Tooltip';

const InfoIcon = ({label, active, onClick, size, style, className}) => {
	return (
		<Tooltip label={label ? label : active ? "Hide Info" : "Show Info"}>
			<Info
				className={`cursor-pointer ${className ? className : ''}${active ? " colour-text-primary" : " colour-text-icon-grey colour-hover-text-neutral-dark-60"}`}
				style={style}
				size={size}
				onClick={() => onClick()}
			/>
		</Tooltip>
	);
}

export default InfoIcon;