import React from 'react';
import Button from '../../components/buttons/Button';
import TextInput from '../../components/inputs/TextInput';
import FormAlertMessage from '../../components/shared/prompts/FormAlertMessage';
import LoginMessageWrapper from '../components/LoginMessageWrapper';

const TwoFactorAuthPage = ({ onKeyDown, value, onChange, onSubmit, error }) => {
	return (
		<div id="two-fa-page">
			<LoginMessageWrapper header="Two Factor Authentication" message={<>A new code has been sent to your email.<br />Enter the code below.</>} />
			{/* Fields */}
			<TextInput
				className="mb-l"
				label="6 Digit Code"
				placeholder="Code"
				onKeyDown={onKeyDown}
				value={value}
				onChange={onChange}
				invalid={error}
			/>
			{error && <FormAlertMessage inlineIcon fullWidth type="danger" message={error} />}
			<Button large fullWidth className="mb-xl" onClick={onSubmit}>
				<div className="font-weight-semi-bold">Sign In Securely</div>
			</Button>
		</div>
	);
}

export default TwoFactorAuthPage;