import React from 'react';

import {  useMemo, useRef } from "react"
import { useComponentId } from './DataController';


export const SideBySideElement = ({ attributes, children, element, style, styleInterpreter }) => {

	const wrapperStyle = useMemo(()=>{
		const interpreted = styleInterpreter(style) || {};
		return interpreted;
	},[style]);

	return <div className='side-by-side'
		{...attributes} 
		style={wrapperStyle} 
	>
		{children}
	</div>
}

export const ImageElement = ({ attributes, children, element, styleInterpreter }) => {
	const style = styleInterpreter(element.style);
	//if children value is empty text, don't render it
	const childrenContent = children && children.length === 1 && children[0].props.text.text === ""
		? null
		: children;
	

	return <div {...attributes} style={style} >
		<div contentEditable={false} className="image-image">
			<img src={element.url} alt=""/>
		</div>
		{childrenContent}
	</div>
}

const convertStyleKey = key=>{
	return key.replace(/[A-Z]/g, upper=>"-" + upper.toLowerCase());
}
const convertStyles = style=>{
	const each = [];
	for (const key in style) {
		if (style.hasOwnProperty(key)) {
			each.push(convertStyleKey(key) + ": " + style[key]);
		}
	}
	each.push("");
	return each.join(";\n");
}

export const TableElement = ({ attributes, children, element, styleInterpreter}) => {

	const { columnWidths } = element;
	const tableRef = useRef();

	const style = {
		display: "grid",
		gridTemplateColumns: columnWidths.join(" ")
	}

	let columns = columnWidths.length;

	const randomId = useComponentId();

	const css = [];
	element.rowStyles
		.forEach((style, row)=>{
			if (!style)
				return;
			const theStyle = styleInterpreter(style);
			if (!theStyle)
				return;
			
			css.push("div#table-" + randomId + ".table-" + randomId + " >div[data-row=\"" + row + "\"] {\n" + convertStyles(theStyle) + "}");
		});

	element.colStyles
		.forEach((style, col)=>{
			if (!style)
				return;
			const theStyle = styleInterpreter(style);
			if (!theStyle)
				return;

			css.push("div#table-" + randomId + ".table-" + randomId + " >div[data-col=\"" + col + "\"] {\n" + convertStyles(theStyle) + "}");
		});

	const theCSS = css.join("\n");

	const tableStyle = styleInterpreter(element.style);
	if (tableStyle) {
		Object.assign(style, tableStyle);
	}
	const tableClass = "table-"+randomId;



	return <>
		{ css.length > 0 && <div contentEditable={false}>
			<style>
				{theCSS} 
			</style>
		</div>}
		<div 
			id={"table-"+randomId}
			{...attributes} 
			className={tableClass} 
			style={style}
			ref={tableRef}
		>
			{children}
		</div>
	</>
}

export const CellElement = props => {

	const { attributes, children, element, style, styleInterpreter,editor } = props;
	const { col, colSpan, row, rowSpan } = element;

	const cellStyle = useMemo(()=>{
		const interpreted = styleInterpreter(style) || {};
		if (colSpan) {
			interpreted.gridColumnEnd = "span " + colSpan;
		}
		if (rowSpan) {
			interpreted.gridRowEnd = "span " + rowSpan;
		}
		return interpreted;
	},[style, element]);

	return <div 
		className="table-cell" 
		data-col={col} 
		data-row={row} 
		data-colspan={colSpan} 
		data-rowspan={rowSpan} 
		{...attributes} 
		style={cellStyle} 
	>
		{children}
	</div>
};

export const TableWrapperElement = props => {

	const { attributes, children, element, style, styleInterpreter,editor } = props;

	const wrapperStyle = useMemo(()=>{
		const interpreted = styleInterpreter(style) || {};
	return interpreted;
	},[style]);

	return <div 
		{...attributes} 
		style={wrapperStyle} 
	>
		{children}
	</div>
};

export const ImageWrapperElement = props => {

	const { attributes, children, element, style, styleInterpreter,editor } = props;

	const wrapperStyle = useMemo(()=>{
		const interpreted = styleInterpreter(style) || {};
	return interpreted;
	},[style]);

	return <div 
		{...attributes} 
		style={wrapperStyle} 
	>
		{children}
	</div>
};


export const getTableStyles = (templateStyles, rowStyles, columnStyles, data) => {
	if (!rowStyles) {
		return;
	}
	const keys = rowStyles.map(obj => obj.key);
	columnStyles.forEach(obj => {
		if (!keys.includes(obj.key)) {
			keys.push(obj.key);
		}
	});

	const myStyles = keys.map(key => {
		const converted = data.getStyle(templateStyles[key], true);
		const val = {};
		val[key] = converted;
		return val;
	});

	const flattenedStyles = {};
	myStyles.forEach(style => Object.assign(flattenedStyles, style));

	if (flattenedStyles.minHeight) {
		flattenedStyles.minHeight = flattenedStyles.minHeight + 'pt';
	}
	
	return flattenedStyles;
}