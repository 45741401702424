import React from 'react';
import FlexWrapper from '../misc/FlexWrapper';
import AnalystCard from '../cards/AnalystCard'

const MobileAnalystList = ({ data }) => {
	
	return (
		<FlexWrapper direction="column" gap="m" fullWidth>
			{data && data.map((analyst, idx) => <AnalystCard data={analyst} key={idx} isMobile />)}
		</FlexWrapper>
	);
}

export default MobileAnalystList;