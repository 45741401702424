import React, { useEffect, useState } from 'react';
import { getAnalystImage, onDocumentClick, downloadDocuments, getImageFromS3, useWatchList, useRemote } from '../../utils/Utils';
import ResearchActionIcons from '../buttons/ResearchActionIcons';
import DetailField from '../shared/detail-section/DetailField';
import { Viewer } from '../shared/research/ResearchViewer';
import Divider from './Divider';
import FlexWrapper from './FlexWrapper';
import ProfileImage from './ProfileImage';
import "./ResearchOverlay.scss";
import TickerSectorItem from './TickerSectorItem';
import { analytics } from '../../utils/Utils';
import TickerCompactIcon from './TickerCompactIcon';
import DetailLabel from '../shared/detail-section/DetailLabel';

const ResearchOverlay = ({ ticker, setFullScreenResearch, publication, analysts, extraInfo, companies, documentViewerOnly }) => {
	const [imageUrl, setImageUrl] = useState("")
	const [entityType, setEntityType] = useState("")
	const [companiesWithPrices, setCompaniesWithPrices] = useState([])
	const [companiesWithInfo, setCompaniesWithInfo] = useState([])
	const [isPdf, setIsPdf] = useState(false)

	const isSector = entityType === "sector"
	const { me, handleWatchListClick } = useWatchList()
	const remote = useRemote()

	let entityName = isSector ? ticker.label : ticker.name
	let entitySubLabel = isSector ? ticker.region : ticker.ric

	useEffect(() => {
		if (ticker && ticker.ric) {
			let [tick, exch] = ticker.ric.split(".")
			getImageFromS3(exch, tick).then(setImageUrl)
		} else if (ticker.label) {
			setImageUrl({ url: ticker.logo })
			setEntityType("sector")

			if (companies) {
				let tickerCalls = []
				let infoCalls = []

				companies && companies.forEach(company => {
					let func = remote.get("/portal/ticker/" + company.ric)
					tickerCalls.push(func)
				}, []);

				companies && companies.forEach(company => {
					let func = remote.get("/portal/ticker/" + company.ric + "/information")
					infoCalls.push(func)
				}, []);

				Promise.all(tickerCalls).then((tickerResp) => {
					setCompaniesWithPrices(tickerResp)
				})

				Promise.all(infoCalls).then((infoResp) => {
					setCompaniesWithInfo(infoResp)
				})
			}
		}
	}, [ticker, companies])

	const price = extraInfo && extraInfo.price && extraInfo.price.price && parseFloat(extraInfo.price.price).toFixed(2) || "-";
	const targetPrice = ticker && ticker.targetPrice && parseFloat(ticker.targetPrice).toFixed(2) || "-"
	const priceAtLabel = extraInfo && extraInfo.price && extraInfo.price.dateTime || "";

	const datafiles = publication && publication.models ? publication.models : [] ;
	const defaultPdf = (publication && publication.files && publication.files.filter(f => f.defaultPdf)) || [];
	return (
		<div className="research-overlay">
			{/* Banner here */}
			<FlexWrapper fullWidth align="center" justify="center" className="research-actions-button-banner">
				<FlexWrapper fullWidth align="center" justify={documentViewerOnly ? "flex-end" : "space-between"} className="research-actions-buttons">
					{!documentViewerOnly && <div className="research-ticker-wrapper">
						<TickerSectorItem size="l" label={entityName} extensionLabel={entitySubLabel} image={imageUrl} />
					</div>}
					<FlexWrapper gap="l" className="research-details-wrapper" wrap justify="flex-end">
						{!documentViewerOnly && (isSector
							?
							<FlexWrapper gap="s" direction="column" align="flex-start">
								{/* <DetailLabel label="Companies Covered" wrap /> */}
								<FlexWrapper direction="row" align="flex-start" fullWidth wrap gap="m">
									{companiesWithPrices && companiesWithPrices.length >= 0
										? companiesWithPrices.map((company, index) => {
											company.ticker.logo = { ticker: company.ticker.ric }
											return (
												<TickerCompactIcon key={index} ticker={company.ticker} prices={company.savedData} target={companiesWithInfo && companiesWithInfo[index] && companiesWithInfo[index]} me={me} setMe={(me) => handleWatchListClick(me)} grouping="sectors" />
											);
										})
										: "-"}
								</FlexWrapper>
							</FlexWrapper>
							:
							<>
								<DetailField className="text-align-right" valueClassName="font-size-header-2" label="Current Rating" value={ticker.currentRating} />
								<DetailField className="text-align-right" valueClassName="font-size-header-2" label={`Price (Delayed)`} value={price} />
								<DetailField className="text-align-right" valueClassName="font-size-header-2" label="Target Price" value={targetPrice} />
							</>
						)}
						{/* <Divider height={40} vertical />
						<ProfileImage src={getAnalystImage(analysts, publication.analystName)} name={publication.analystName} /> */}

						{/* <ProfileImage src="https://i.ibb.co/9vjYZzn/pexels-photo-1024311.jpg" /> */}
						{!documentViewerOnly && <Divider height={40} vertical />}
						<ResearchActionIcons
							size={16}
							setFullScreenResearch={setFullScreenResearch}
							downloadDisabled={defaultPdf.length === 0}
							downloadOnClick={(e) => { analytics.track("downloadPDF", { publication }); downloadDocuments(e, defaultPdf) }}
							printDisabled={defaultPdf.length === 0 || isPdf}
							printOnclick={(e) => { analytics.track("printPDF", { publication }); downloadDocuments(e, defaultPdf) }}
							dataDisabled={documentViewerOnly || publication?.models?.length === 0}
							dataOnClick={(e) => { analytics.track("downloadData", { publication }); downloadDocuments(e, publication.models, true) }}
						/>
					</FlexWrapper>
				</FlexWrapper>
			</FlexWrapper>
			{/* Research to go here*/}
			<Viewer publication={publication} fullScreenResearch setIsPdf={setIsPdf} fillScreen />
		</div>
	);
};

export default ResearchOverlay;