import React, { Component, useEffect, useState } from "react";
import { useRemote } from "../../utils/Utils";

const Divider = ({config})  => {

	const style = config.orientation === "HORIZONTAL" ? {
		width: config.length + "pt",
		height: config.thickness + "pt"
	} : {
		height: config.length + "pt",
		width: config.thickness + "pt"
	}
	style.backgroundColor = config.color;
	return <div style={style}></div>
}

export default Divider;
