import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarExtensionLink from "./SidebarExtensionLink";
import "./SidebarExtensionLinkList.scss";

const SidebarExtensionLinkList = (props) => {
	const { links } = props;
	const location = useLocation();

	return (
		<div className="sidebar-extension-link-list">
			{links && links.length > 0 && links.map((link, idx) => (
				<SidebarExtensionLink link={link.path} active={location.pathname === link.path} key={idx} icon={link.icon && link.icon}>{link.name}</SidebarExtensionLink>
			))}
		</div>
	);
}

export default SidebarExtensionLinkList;