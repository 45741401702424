import React from 'react';
import { AlertCircle, AlertTriangle, CheckCircle, Info } from 'react-feather';
import { toast as toasty } from 'react-toastify';
import Button from '../buttons/Button';
import FlexWrapper from '../misc/FlexWrapper'

export const UndoToastMessage = ({onClick, message}) => (
	<FlexWrapper justify="space-between">{message}<Button onClick={() => {onClick(); toasty.dismiss()}} className="toast-undo-button" size="small" variant="secondary">Undo</Button></FlexWrapper>
);

export const toast = (message, params) => toasty(message, {autoClose: 2500, ...params});
export const toastInfo = (message, params) => toasty.info(message, {icon: <Info />, autoClose: 2500, ...params});
export const toastSuccess = (message, params) => toasty.success(message, {icon: <CheckCircle />, autoClose: 2500, ...params});
export const toastWarning = (message, params) => toasty.warning(message, {icon: <AlertTriangle />, autoClose: 2500, ...params});
export const toastDanger = (message, params) => toasty.error(message, { icon: <AlertCircle />, autoClose: 2500, ...params });