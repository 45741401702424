import React from 'react';
import "./LoginMessageWrapper.scss";

const LoginMessageWrapper = ({header, message}) => {
	return (
		<div className="login-message-wrapper">
			<h1>{header}</h1>
			<p className="mt-none">{message}</p>
		</div>
	);
}

export default LoginMessageWrapper;