import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../components/misc/Logo';
import "./LoginTopBanner.scss";

const LoginTopBanner = ({ header, message }) => {
	const navigate = useNavigate();
	return (
		<div className="login-banner">
			<Logo width={177} onClick={() => navigate("/")} className="cursor-pointer" />
		</div>
	);
}

export default LoginTopBanner;