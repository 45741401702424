import React, { useState, useEffect, useMemo } from "react";
import { Search } from "react-feather";
import _ from 'lodash'
import TextInput from './TextInput'
import { debounce } from "throttle-debounce";
import { analytics } from "../../utils/Utils";

const SearchTermFilter = (props) => {
	const { dataSet, setFilteredData, path, hasSearchTerm, placeholder, size, width, isClearable, setSearchTerm: propagateSearchTerm, doesntExist, noIcon, trackingLabel, fullWidth } = props;

	const [searchTerm, setSearchTerm] = useState("")


	useEffect(() => {
		handleSearch(searchTerm)
	}, [dataSet])

	const debounceTrack = useMemo(() => debounce(
		1000,
		(term) => {
			analytics.track(trackingLabel && trackingLabel, { searchTerm: term })
		}),[]);

	useEffect(() => {
		handleSearch(searchTerm)
		hasSearchTerm(searchTerm ? true : false)
		if (trackingLabel && searchTerm.length > 1) debounceTrack(searchTerm)
	}, [searchTerm])

	const setPath = (datum) => {
		let pathType = typeof path
		switch (pathType) {
			case "string":
				return datum[path]
			case "object":
				let searchableString = ""
				path.forEach((locater) => {
					let stringToAdd = _.get(datum, locater)
					searchableString = searchableString + ' ' + stringToAdd
				})
				return searchableString
			default:
				break;
		}
	}

	const handleSearch = (searchTerm) => {
		let searchTermArray = searchTerm.toLowerCase().split(" ")
		let newFilteredData = []
		newFilteredData = dataSet.filter((datum) => {
			setPath(datum)
			let formattedDatum = setPath(datum).toLowerCase()
			let verify = searchTermArray.map((term) => {
				if (formattedDatum.includes(term)) {
					return true
				} else {
					return false
				}
			})
			if (verify.includes(false) === false) {
				return datum
			}
		})
		setFilteredData(newFilteredData)
		if (propagateSearchTerm) {
			propagateSearchTerm({ searchTerm, filteredData: newFilteredData })
		}
	}

	return (
		<TextInput
			// {...props}
			type="search"
			placeholder={placeholder}
			onChange={e => setSearchTerm(e.target.value)}
			size={size}
			width={width}
			fullWidth={fullWidth}
			icon={noIcon ? "" : <Search />}
			clearText={() => setSearchTerm("")}
			isClearable={isClearable}
			value={searchTerm}
		>

		</TextInput>
	);
}

export default SearchTermFilter;
