import React, { useEffect, useState } from 'react';
import SearchTermFilter from '../../../components/inputs/SearchTermFilter';
import FlexWrapper from '../../../components/misc/FlexWrapper';
import ProfileImage from '../../../components/misc/ProfileImage';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../../layout/grid/page/Page';
import PageGridItem from '../../../layout/grid/page/PageGridItem';
import useWindowDimensions, { useRemote, useStorage } from '../../../utils/Utils';
import { useNavigate } from 'react-router-dom';
import MobileAnalystList from '../../../components/lists-and-tables/MobileAnalystList';
import Table from '../../../components/lists-and-tables/Table'
import Divider from '../../../components/misc/Divider';
import { analytics } from '../../../utils/Utils';

const AnalystsPage = () => {
	const storage = useStorage()
	const remote = useRemote();
	const navigate = useNavigate()
	const { track, page } = analytics;

	const { width, height } = useWindowDimensions();

	const [analysts, setAnalysts] = useState([]);
	const [filteredAnalysts, setFilteredAnalysts] = useState([])
	const [hasSearchTerm, setHasSearchTerm] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		setLoading(true)
		storage.getOrFetch("/portal/analysts").then((resp) => {
			setAnalysts(resp)
			setFilteredAnalysts(resp)
			setLoading(false)
		});
		page()
	}, [])

	const colWidths = [20 + "%", 20 + "%", 10 + "%", 20 + "%"]
	const headers = ["Name", "Email", "Phone", "Role"];
	const dataMap = (dataToMap) => dataToMap && dataToMap.map((analyst, idx) => {
		return (
			<tr key={idx} className="cursor-pointer" onClick={() => navigate("/analysts/" + analyst.name)}>
				<td>
					<FlexWrapper align="center" gap="s">
						<ProfileImage src={analyst.photo} user={analyst} />
						{analyst.name}
					</FlexWrapper>
				</td>
				<td><div className="truncate">{analyst.email}</div></td>
				<td>{analyst.phone}</td>
				<td>{analyst.title}</td>
			</tr>
		);
	})

	return (
		<Page id={"analysts-page"} fixedBanner>
			{/* Modal */}
			<PageContentBanner divider gap="s" direction="column" style={{ position: "sticky", top: width >= 900 ? "-32px" : "-20px", zIndex: 11, backgroundColor: "#fff" }}>
				<h2>
					Analysts
				</h2>
				{analysts && analysts.length > 0 && <>
					<Divider />
					<SearchTermFilter
						size="small"
						fullWidth={width <= 900}
						placeholder="Search analysts"
						isClearable
						dataSet={analysts}
						setFilteredData={setFilteredAnalysts}
						path={["companyName", "firstName", "lastName", "preferredName", "email", "role"]}
						hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
						trackingLabel={"analystsSearch"}
					/>
				</>
				}
			</PageContentBanner>
			<PageContentBodyGrid>
				<PageGridItem colSpan="12">
					{(width > 768) && (height > 490)
						? <Table
							minWidth={1000}
							colWidths={colWidths}
							headers={headers}
							dataMap={dataMap(hasSearchTerm ? filteredAnalysts : analysts)}
							noResults={{ message: "No users found", subMessage: "Try adding a new user." }}
							dataLoading={loading}
						/> : <MobileAnalystList data={hasSearchTerm ? filteredAnalysts : analysts} />}
				</PageGridItem>
			</PageContentBodyGrid>
		</Page>
	);
}

export default AnalystsPage;