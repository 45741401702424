import { useState, useEffect, useRef } from 'react';
import RemoteImpl from '@threeskye/global/dist/RemoteImpl';
import SessionStorage from '@threeskye/global/dist/SessionStorage';
import { Analytics } from 'analytics';
import format from 'date-fns/format';
import mixpanelPlugin from '@analytics/mixpanel'
import { parseISO } from 'date-fns';
import { memoize, partialRight } from 'lodash';

import * as pdfjs from 'pdfjs-dist';
// import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';
// import { DocumentInitParameters } from 'pdfjs-dist/types/src/display/api';

const remote = new RemoteImpl();

remote.createTokenHeader = (url) => {
	let token = window.sessionStorage.getItem("3skye.auth.token");
	if (!token) {
		token = localStorage.getItem("3skye.persistent.token");
	}
	if (!token) {
		console.error("Requesting api ",url," but no headers")
		return {
			headers: {}
		};
	}
	return {
		headers: {
			"X-3Skye-Session": token
		}
	};
}
remote.setToken = (resp) => {
	let newToken = resp.headers.get("X-3Skye-Set-Token");
	if (newToken) {
		window.sessionStorage.setItem("3skye.auth.token", newToken);
	}
}
remote.logout = () => {
	//window.sessionStorage.removeItem("3skye.auth.token");
	if (remote.registeredLogoutAction)
		remote.registeredLogoutAction();
}

remote.handleResponse = (resp, options) => {
	if (resp.ok) {
		// console.log("Setting token ", resp.headers.get("X-3Skye-Set-Token"))
		remote.setToken(resp);
		if (options.rawResponse)
			return resp;
		else 
			return resp.json();
	} else {
		if (resp.status === 418 || resp.status === 401) {
			console.log("resp status is bad, handling session timeout", resp.status)
			remote.handleSessionTimeout();
		} else {
			//Update the user token regardless
			// remote.setToken(resp);
			console.log(resp)
		}
		return Promise.reject(resp.status);
	}
}
remote.get = (apiURL, options) => {
	let headers = remote.createTokenHeader(apiURL);
	options = options || {};
	var url = remote.base + apiURL;
	if (apiURL.indexOf("/um") != 0) {
		if (!headers || !headers.headers || !headers.headers["X-3Skye-Session"]) {
			console.log(`Aborting get call to ${url} because no auth`);
			return new Promise(function(resolve, reject) {resolve(null)}); 
		}
	}
	if (options.timeout !== null) {
	   return fetch(url, headers)
			.then((resp)=>{
				return remote.handleResponse(resp, options);
			});
		//TODO handle unauthorised
	} else {
		return remote.timeout(20000, fetch(url, headers))
			.then((resp)=>{
				return remote.handleResponse(resp, options);
			});
	}
}

remote.send = (method, apiURL, content, options) => {
	let headers = remote.createTokenHeader(apiURL);
	options = options || {};
	var url = remote.base + apiURL;

	if (apiURL.indexOf("/um") != 0) {
		if (!headers || !headers.headers || !headers.headers["X-3Skye-Session"]) {
			return new Promise(function(resolve, reject) {resolve(null)}); 
		}
	}
	headers.headers["Content-Type"] = "application/json; charset=utf-8";
	Object.assign(headers, {
		method: method,
		body: JSON.stringify(content),
		credentials: "include"
	});

	if (options.timeout !== null) {
		return fetch(url, headers)
		.then((resp)=>{
			return remote.handleResponse(resp, options);
		});
	} else {
		return remote.timeout(20000, fetch(url, headers))
			.then((resp)=>{
				return remote.handleResponse(resp, options);
			});
			// .then((data)=>{
			//         handler(data);
			// }).catch(e=>{
			//         if (timeout) timeout(e);
			// });
	}
}



export const useRemote = () => remote;

const storage = new SessionStorage(remote);
export const useStorage = () => {
	storage.remote = remote;
	return storage;
}

export const useI18n = () => {
	const [data, setData] = useState([]);
	const remote = useRemote();

	useEffect(() => {
		remote.get("/configuration/crm/i18n/en-NZ").then(setData);
	}, [])
	return (val) => data[val];
}

function getWindowDimensions() {
//	const { innerWidth: width, innerHeight: height } = window;

	let height = window.innerHeight;
	let width = window.innerWidth;

	if (window.outerWidth < window.innerWidth) {
		width = window.outerWidth;
	}

	return {
		width,
		height,
	};
}

export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowDimensions;
}

export function formatDate(date) {
	let formattedDate = format(new Date(date), "dd/MM/yyyy")
	return formattedDate
}

export function formatDateTime(date) {
	let formattedDate = format(new Date(date), "dd/MM/yyyy HH:mm")
	return formattedDate
}


export const downloadDocuments = async (event, files, modelDownload) => {
	event && event.stopPropagation();
	for (let file of files) {
		try {
			if (modelDownload) {
				onModelClick(file)
			} else {
				await onDocumentClick(file);
			}
			await new Promise((resolve) => setTimeout(resolve, 1000));  // Wait for 1 second to allow for multiple files
		} catch (error) {
			console.error('Error occurred during downloads:', error);
		}
	}
};

export const onDocumentClick = (doc) => {
	if (doc && doc.id) {
		remote.get(`/documents/document/${doc.id}`).then((response) => {
			if (response && response.url) {
				const link = document.createElement('a');
				link.href = response.url;
				link.setAttribute(
					'download',
					doc.fileName,
				);

				// Append to html link element page
				document.body.appendChild(link);

				// Start download
				link.click();

				// Clean up and remove the link
				link.parentNode.removeChild(link);
			}
		});
	}
}

export const onModelClick = (doc) => {
	if (doc) {
		remote.get(`/documents/model/${doc}`).then((response) => {
			if (response && response.url) {
				const filename = response.filename;
				const link = document.createElement('a');
				link.href = response.url;
				link.setAttribute(
					'download',
					filename,
				);

				// Append to html link element page
				document.body.appendChild(link);

				// Start download
				link.click();

				// Clean up and remove the link
				link.parentNode.removeChild(link);
			}
		});
	}
}

const uniqueSession = Math.floor(Math.random() * 0xffffff).toString(36).padEnd(6, "0");

export const analytics = Analytics({
	session: Math.random(1000000),
	app: 'client-portal',
	plugins: [
		{
			name: 'client-portal-homebrew-log-plugin',
			bootstrap: ({ payload, config, instance }) => {
				console.log("Analytics, bootstrap called ", payload, config, instance)
				const key = sessionStorage.getItem("3skye-analytics-key");
				if (!key) {
					sessionStorage.setItem("3skye-analytics-key", uniqueSession);
				}
			},
			page: ({ payload }) => {
				payload.url = payload.properties.path;
				payload.anonymousId = payload.anonymousId+"-"+sessionStorage.getItem("3skye-analytics-key");
				remote.post("/portal/a/p", payload);
			},
			track: ({ payload }) => {
				payload.anonymousId = payload.anonymousId+"-"+sessionStorage.getItem("3skye-analytics-key");
				remote.post("/portal/a/e", payload);
			},
			identify: ({ payload }) => {
				const data = {
					timezone: (new Date()).getTimezoneOffset() / 60,
					referrer: document.referrer,
					previousSites: window.history.length
				};
				if (navigator) {
					data.browserName = navigator.appName;
					data.browserEngine = navigator.product;
					data.browserVersionA = navigator.appVersion;
					data.browserVersionB = navigator.userAgent;
					data.language = navigator.language;
					data.browserPlatform = navigator.platform;

					//geolocation

					if (navigator.geolocation) {
						//If we use this it will trigger a permission request.  Do we want that?
					}
				}
				if (window.screen) {
					const screen = window.screen;
					data.screenWidth = screen.width;
					data.screenHeight = screen.height;
					data.screenAvailWidth = screen.availWidth;
					data.screenAvailHeight = screen.availHeight;
					data.colorDepth = screen.colorDepth;
					data.pixelDepth = screen.pixelDepth;
				}
				if (document) {
					data.documentWidth = document.width;
					data.documentHeight = document.height;
				}

				const handleLocation = (response) => {
					if (response.coords && response.coords.latitude && response.coords.longitude) {
						let { latitude, longitude, accuracy, altitude, altitudeAccuracy, heading, speed } = response.coords
						data.location = JSON.stringify({ latitude, longitude, accuracy, altitude, altitudeAccuracy, heading, speed, timestamp: response.timestamp })
					}
					data.session = payload.anonymousId+"-"+sessionStorage.getItem("3skye-analytics-key");
					remote.post("/portal/a/i", data);
				}

				const throwLocation = (res) => {
					data.session = payload.anonymousId+"-"+sessionStorage.getItem("3skye-analytics-key");
					remote.post("/portal/a/i", data);
				}

				navigator.geolocation.getCurrentPosition((res) => handleLocation(res), (res) => throwLocation(res))
			}
		},
		mixpanelPlugin({
			token: '0cf6096a94e9fb565dfae2e4125f7846'
		})
	]
})

export const watched = (me, ric, path) => {
	if (path) {
		if (!me || !me.watched) {
			return false;
		}

		if (path.indexOf('/company') === 0) {
			return me.watched.tickers.findIndex(x => path.includes(x)) > -1;
		}
		if (!me.watched.group) {
			return false;
		}
		return me.watched.group.findIndex(x => path.includes(x)) > -1;
	}

	return me
		&& me.watched
		&& ((me.watched.analysts && (me.watched.analysts.indexOf(ric) > -1))
			|| (me.watched.sectors && (me.watched.sectors.indexOf(ric) > -1))
			|| (me.watched.tickers && (me.watched.tickers.indexOf(ric) > -1)));
}

export const watchedTicker = (me, ticker) => {
	return watched(me, ticker);
}

export const getResearchImage = (research) => {
	if (!research) {
		return null;
	}
	if (research.url) {
		return research.url;
	}
}

export const getImage = (tickers, ticker) => {

	const found = tickers.find(t => t.ric === ticker);
	if (found) {
		return found.logo;
	}
	return null;
}

export const getAnalystImage = (analysts, name) => {
	if (!analysts || !name) {
		return null;
	}
	const found = analysts.find(a => a.name === name);
	if (found) {
		return found.photo;
	}
	return null;
}

export const getAnalystEmail = (analysts, name) => {
	if (!analysts || !name) {
		return null;
	}
	const found = analysts.find(a => a.name === name);
	if (found) {
		return found.email;
	}
	return null;
}

export const getAnalystName = (analysts, name) => {
	if (!analysts || !name) {
		return null;
	}
	const found = analysts.find(a => a.name === name);
	if (found) {
		return found.name;
	}
	return null;
}

export const getAnalyst = (analysts, document) => {
	if (!analysts || !document) {
		return null;
	}
	let author = document.analystName;
	const found = analysts.find(a => a.name === author);
	if (found) {
		return found;
	}
	return { name: author };
}


/**
 * The `useInfiniteScroll` function is a custom hook in JavaScript that enables infinite scrolling
 * behavior by detecting when the user has scrolled to the bottom of a scrollable element and
 * triggering a callback function.
 * @param callback - The callback parameter is a function that will be called when the user has
 * scrolled to the bottom of the scrollable element. This function can be used to fetch more data or
 * perform any other action needed when infinite scrolling is triggered.
 * @param scrollableElementChildRef - The ref of the child element inside the scrollable element.
 * @param scrollableElementRef - The `scrollableElementRef` is a reference to the scrollable element
 * that you want to apply the infinite scroll behavior to.
 * @param callbackIsThenable - The parameter `callbackIsThenable` is a boolean value that indicates
 * whether the callback function returns a promise (thenable) or not. If `callbackIsThenable` is
 * `true`, it means the callback function returns a promise, and if it's `false`, it means the callback
 * function
 * @returns The `useInfiniteScroll` function returns an array with two elements: `isFetching` and
 * `setIsFetching`.
 */
export const useInfiniteScroll = (callback, scrollableElementChildRef, scrollableElementRef, callbackIsThenable) => {
	const [isFetching, setIsFetching] = useState(false);

	useEffect(() => {
		const element = scrollableElementRef.current;

		element.addEventListener('scroll', onScroll);
		element.addEventListener('touchmove', onScroll);

		return () => {
			element.removeEventListener('scroll', onScroll);
			element.removeEventListener('touchmove', onScroll);
		};
	});

	useEffect(() => {
		if (!isFetching) return;
		if (callbackIsThenable) {
			callback().then(() => setIsFetching(false))
		}
		else callback();
	}, [isFetching]);

	const hasScrolledToBottom = () => {
		if (scrollableElementChildRef.current && scrollableElementRef.current) {
			return scrollableElementChildRef.current.getBoundingClientRect().bottom <= scrollableElementRef.current.getBoundingClientRect().bottom;
		}
	}

	const onScroll = () => {
		if (hasScrolledToBottom()) {
			setIsFetching(true);
		} else if (isFetching === true && !callbackIsThenable) setIsFetching(false)
	}

	return [isFetching, setIsFetching];
};

export const goToResearch = (document, navigate) => {

	var ua = navigator.userAgent.toLowerCase();
	var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");

	const publication = document && document.publicationId ? document.publicationId : document.publicationId ? document.publicationId : document.id
	const ric = document && document.coveredShortName ? document.coveredShortName : document.ric
	const type = document && document.type ? document.type : document.template ? document.template : document.templateName
	if (!document.read) {
		document.read = true;
	}

	const defaultPdf = (document && document.files && document.files.filter(f => f.defaultPdf)) || []

	if ( isAndroid && !document.hasHtmlVersion && document.hasHtmlVersion !== undefined) {
		downloadDocuments(null, defaultPdf, false)
	} else  {
		navigate(document.path);
	}
}

export const useWatchList = () => {
	const [me, setMe] = useState({})

	const handleWatchListClick = (me) => {
		storage.put("/portal/me", me);
		storage.refresh('/portal/dashboard')


		// console.log("Handling a watch list click for ", ticker, grouping)
		// let getCode = () => {
		// 	switch (grouping) {
		// 		case "tickers":
		// 			return ticker.ric
		// 		case "sectors":
		// 		case "group":
		// 			if(ticker.path) return ticker.path
		// 			if(ticker.groupName) return ticker.groupName
		// 			if(ticker.ric) return ticker.ric
		// 			return ticker;
		// 		case "analysts":
		// 			return ticker.name
		// 		default: break;
		// 	}
		// }
		// const code = getCode()

		// console.log("Code is ", code)
		// let listToCheck = me && me.watched && me.watched[grouping] ? me.watched[grouping] : []
		// console.log("list to check", listToCheck)
		// let newWatchList = listToCheck && listToCheck.find((watchItem) => {
		// 	return code === watchItem
		// })
		// 	? listToCheck.filter((watchItem) => code !== watchItem)
		// 	: [...listToCheck, code]
		// console.log("new watch list ", newWatchList)

		// let newMe = { ...me }
		// newMe.watched[grouping] = newWatchList
		// storage.put("/portal/me", newMe);
		// storage.refresh('/portal/dashboard')
	}


	useEffect(() => {
		storage.getOrFetch("/portal/me").then(setMe)
		storage.watch("/portal/me", setMe)
		return storage.unwatch("/portal/me")
	}, [])

	return { me, handleWatchListClick }
}

export const getPortalSearchOptions = (term, setFetchingResults, tickerList) => {
	setFetchingResults(true)
	return remote.get("/portal/s?q=" + term)
		.then(results => {
			analytics.track("portalWideSearch", { searchTerm: term })
			const analysts = results.analysts ? results.analysts.map((value) => { return { label: value.name, value, type: "analyst" } }) : []

			const notes = results.notes ? results.notes.map((value) => { return { label: value.title, value: { ...value, logo: `/api/public/images/note/${value.publicationId}.png` }, type: "note" } }) : []

			const sectors = results.sector ? results.sector.map((value) => { return { label: value.groupName, value, type: "sector", logo: `/api/public/images/group/${value.id}.png` } }) : []

			const tickers = results.tickers ? results.tickers.map((value) => { return { label: value.name, value: { ...value, logo: `/api/public/images/ticker/${value.id}.png` }, type: "ticker" } }) : []

			setFetchingResults(false)
			let sortedNotes = notes.sort((a, b) => {
				if ((!a || !a.value || !a.value.publishedDate) && (!b || !b.value || !b.value.publishedDate)) {
					return 0
				}
				if (!a || !a.value || !a.value.publishedDate) {
					return -1
				}
				if (!b || !b.value || !b.value.publishedDate) {
					return 1
				}
				let aDate = a.value.publishedDate
				let bDate = b.value.publishedDate
				return aDate.localeCompare(bDate)
			})
			return [...analysts, ...sectors, ...tickers, ...sortedNotes.reverse()]
		})
		.catch(() => {
			setFetchingResults(false)
			return []
		})
}

export const selectPortalSearchOption = (selection, navigate) => {
	if (selection) {
		let { value, type } = selection
		switch (type) {
			case "ticker":
				navigate("/company/" + value.ric)
				break;
			case "sector":
				navigate(`group/${value.groupingDetails.urlSafeName}/${value.urlSafeName}`)
				break;
			case "note":
				navigate(value.path)
				break;
			case "analyst":
				navigate("/analysts/" + value.name)
				break;

			default:
				break;
		}
	}
}

export const getPortalSearchExtensionLabel = (data) => {
	if (data) {
		switch (data.type) {
			case "ticker":
			case "tickers":
				return `${data.value.ric}${data.value.country ? " | " + data.value.country : ""}`
			case "sector":
			case "sectors":
				return `${data.type}`
			case "note":
			case "notes":
				return `${data.value.coveredShortName}${data.value.analystName ? " | " + data.value.analystName : ""}${data.value.publishedDate ? " | " + format(parseISO(data.value.publishedDate), "dd/MM/yyyy") : ""}`
			case "analyst":
			case "analysts":
				return `${data.value.title}`
			default:
				break;
		}
	}
}

export const getPortalSearchLogo = (data) => {
	if (data) {
		switch (data.type) {
			case "ticker":
			case "tickers":
				return {
					ticker: data.value.ric
				}
			case "sector":
			case "sectors":
				return {
					url: data.value.logo
				}
			case "note":
			case "notes":
				return data.value.imageUrl
		}
	}
}

export const getIdentifyingValue = (data) => {
	if (data) {
		switch (data.type) {
			case "ticker":
			case "tickers":
				return data.value.ric
			case "sector":
			case "sectors":
				return data.value.name
			case "analyst":
			case "analysts":
				return data.value.email
			default:
				break;
		}
	}
}

export const returnGroupingFromType = (type) => {
	switch (type) {
		case "ticker":
			return "tickers"
		case "sector":
			return "sectors"
		case "analyst":
			return "analysts"
		default:
			break;
	}
}

export const expiringMemo = partialRight(memoize, function memoResolver(...args) {
	const time = (new Date()).getMinutes();

	args.push({ time });

	const cacheKey = JSON.stringify(args);

	return cacheKey;
});

export const getImageFromS3 = async (exchange, tickerCode) => {
	let countryCode = exchange

	if (exchange === 'NZX') {
		countryCode = 'NZ';
	} else if (exchange === 'ASX') {
		countryCode = 'AU'
	} else if (exchange === 'NYSE') {
		countryCode = 'US'
	}

	try {
		const response = await fetch(`https://dja724jxx1.execute-api.ap-southeast-2.amazonaws.com/GenerateAssetSignedUrl?folder=${countryCode}&filename=${tickerCode}.png`);
		const data = await response.json();
		return data;
	} catch (error) {
		return { url: '' };
	}
}


function isFunction(value) {
	return typeof value === 'function';
}

export const usePdf = ({
	canvasRef,
	path,
	onDocumentLoadSuccess,
	onDocumentLoadFail,
	onPageLoadSuccess,
	onPageLoadFail,
	onPageRenderSuccess,
	onPageRenderFail,
	scale = 1,
	rotate = 0,
	page = 1,
	cMapUrl,
	cMapPacked,
	workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`,
	withCredentials = false,
}) => {
	const [pdfDocument, setPdfDocument] = useState();
	const [pdfPage, setPdfPage] = useState();
	const renderTask = useRef(null);
	const onDocumentLoadSuccessRef = useRef(onDocumentLoadSuccess);
	const onDocumentLoadFailRef = useRef(onDocumentLoadFail);
	const onPageLoadSuccessRef = useRef(onPageLoadSuccess);
	const onPageLoadFailRef = useRef(onPageLoadFail);
	const onPageRenderSuccessRef = useRef(onPageRenderSuccess);
	const onPageRenderFailRef = useRef(onPageRenderFail);

	const remote = useRemote();

	// assign callbacks to refs to avoid redrawing
	useEffect(() => {
		onDocumentLoadSuccessRef.current = onDocumentLoadSuccess;
	}, [onDocumentLoadSuccess]);

	useEffect(() => {
		onDocumentLoadFailRef.current = onDocumentLoadFail;
	}, [onDocumentLoadFail]);

	useEffect(() => {
		onPageLoadSuccessRef.current = onPageLoadSuccess;
	}, [onPageLoadSuccess]);

	useEffect(() => {
		onPageLoadFailRef.current = onPageLoadFail;
	}, [onPageLoadFail]);

	useEffect(() => {
		onPageRenderSuccessRef.current = onPageRenderSuccess;
	}, [onPageRenderSuccess]);

	useEffect(() => {
		onPageRenderFailRef.current = onPageRenderFail;
	}, [onPageRenderFail]);

	useEffect(() => {
		pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
	}, [workerSrc]);

	useEffect(() => {

		remote.get(path, { rawResponse: true })
			.then((resp) => resp.arrayBuffer())
			.then(array => {
				pdfjs.getDocument({ data: array }).promise.then(
					loadedPdfDocument => {
						setPdfDocument(loadedPdfDocument);

						if (isFunction(onDocumentLoadSuccessRef.current)) {
							onDocumentLoadSuccessRef.current(loadedPdfDocument);
						}
					},
					() => {
						if (isFunction(onDocumentLoadFailRef.current)) {
							onDocumentLoadFailRef.current();
						}
					}
				);
			})


	}, [path]);

	useEffect(() => {
		// draw a page of the pdf
		const drawPDF = (page) => {
			// Because this page's rotation option overwrites pdf default rotation value,
			// calculating page rotation option value from pdf default and this component prop rotate.
			const rotation = rotate === 0 ? page.rotate : page.rotate + rotate;
			const dpRatio = window.devicePixelRatio;
			const adjustedScale = scale * dpRatio;
			const viewport = page.getViewport({ scale: adjustedScale, rotation });
			const canvasEl = canvasRef && canvasRef.current;
			if (!canvasEl) {
				return;
			}

			const canvasContext = canvasEl.getContext('2d');
			if (!canvasContext) {
				return;
			}

			canvasEl.style.width = `${viewport.width / dpRatio}px`;
			canvasEl.style.height = `${viewport.height / dpRatio}px`;
			canvasEl.height = viewport.height;
			canvasEl.width = viewport.width;

			// if previous render isn't done yet, we cancel it
			if (renderTask.current) {
				renderTask.current.cancel();
				return;
			}

			renderTask.current = page.render({
				canvasContext,
				viewport,
			});

			return renderTask.current.promise.then(
				() => {
					renderTask.current = null;

					if (isFunction(onPageRenderSuccessRef.current)) {
						onPageRenderSuccessRef.current(page);
					}
				},
				(reason) => {
					renderTask.current = null;

					if (reason && reason.name === 'RenderingCancelledException') {
						drawPDF(page);
					} else if (isFunction(onPageRenderFailRef.current)) {
						onPageRenderFailRef.current();
					}
				}
			);
		};

		if (pdfDocument) {
			pdfDocument.getPage(page).then(
				loadedPdfPage => {
					setPdfPage(loadedPdfPage);

					if (isFunction(onPageLoadSuccessRef.current)) {
						onPageLoadSuccessRef.current(loadedPdfPage);
					}

					drawPDF(loadedPdfPage);
				},
				() => {
					if (isFunction(onPageLoadFailRef.current)) {
						onPageLoadFailRef.current();
					}
				}
			);
		}
	}, [canvasRef, page, pdfDocument, rotate, scale]);

	return { pdfDocument, pdfPage };
};
