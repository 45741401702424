import React, { Component } from "react";
import SimpleTable from "./SimpleTable";

class YearlyTable extends Component {

	render() {
		const { config, ...otherProps } = this.props;

		const tableConfig = {};
		Object.assign(tableConfig, config);
		const cells = [];
		config.rows.forEach(row=>{
			const rowCells = [row.header];
			cells.push(rowCells);
			const value = row.values.value;

			//First one will determine number of iterations
			let match = value.match(/\[(\-?\d+)\~(\-?\d+)\]/);						//eslint-disable-line no-useless-escape
			const count = parseInt(match[2]) - parseInt(match[1]) + 1;

			for (let i=0; i<count; i++) {
				let iValue = value;
				while (match = iValue.match(/\[(\-?\d+)\~(\-?\d+)\]/)) {			//eslint-disable-line no-useless-escape
					const year = parseInt(match[1]) + i
					iValue = iValue.substring(0, match.index) + "[" + year + "]" + iValue.substring(match.index + match[0].length);
				} 
				rowCells.push({
					value: iValue
				});
			}
		});

		tableConfig.cells = cells;

		return <SimpleTable configuration={tableConfig} {...otherProps} />
	}
}

export default YearlyTable;
