import React from "react";
import { X } from "react-feather";
import AsyncSelect from "react-select/async";
import "./SearchField.scss";

const AsyncField = ({ components, classNames, autoFocus, icon, size, minWidth, width, onClear, innerRef, openMenuOnFocus, loadOptions, onInputChange, isLoading, menuPortalTarget, menuPosition, ...otherProps }) => {
	const mainClassName = "search-select-field";
	let className = [mainClassName, classNames].filter((cn) => !!cn).join(" ");

	if (icon) {
		className += " search-field-icon";
	}
	if (size) {
		className += " search-field-size-" + size;
	}
	if (width) {
		className += " search-field-width-" + width;
	}
	if (minWidth) {
		className += " search-field-min-width";
	}

	return <AsyncSelect
		{...otherProps}
		isMulti={false}
		components={{ ...components, DropdownIndicator: () => null, IndicatorSeparator: () => null, ClearIndicator: () => <X onClick={onClear && onClear} /> }}
		cacheOptions
		className={className}
		classNamePrefix={mainClassName}
		autoFocus={autoFocus}
		ref={innerRef}
		menuPosition={menuPosition ? menuPosition : "fixed"}
		openMenuOnFocus={openMenuOnFocus}
		menuPortalTarget={menuPortalTarget ? menuPortalTarget : document.getElementById("async-select-portal")} 
		styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
		isLoading={isLoading}
		// cacheOptions
		loadOptions={loadOptions}
		onInputChange={onInputChange}
	/>
		;

}

export default AsyncField;
