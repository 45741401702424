// Documentation ----> https://popper.js.org/
import React from 'react';
import './PopupMenu.scss';
import PopupMenuItem from './PopupMenuItem';

const PopupMenu = (props) => {
    const { className, children, menuItems, width, padding, smallText } = props;

	return (
		<div className={`popup-menu${padding ? " p-m" : ""}${className ? " " + className : ""}`} style={{width: width}}>
			{menuItems ? menuItems.map((item, idx) => (
				<PopupMenuItem noRadius={!padding} smallText={smallText} key={idx} label={item.name ? item.name : item} icon={item.icon && item.icon} onClick={item.onClick} />
			)) : children}
		</div>
	);
};

export default PopupMenu;