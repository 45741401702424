import React from 'react';
import FlexWrapper from '../misc/FlexWrapper';
import "./Tabs.scss";

export const Tab = ({label, children, onClick, active}) => {
	return <div className={`tab${active ? " tab-active" : ""}`} onClick={onClick}>{label || children}</div>;
}

const Tabs = ({tabs}) => {
	return (
		<FlexWrapper className="tab-group" gap="s">
			{tabs && tabs.map((tab, idx) => <Tab key={idx} onClick={tab.onClick} active={tab.active}>{tab.label}</Tab>)}
		</FlexWrapper>
   );
}

export default Tabs;