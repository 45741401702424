import React, { useEffect, useState, useRef, useLayoutEffect, forwardRef, createRef, useCallback } from "react";
import { PageContentBodyGrid } from "../../../layout/grid/page/Page";
import PageGridItem from "../../../layout/grid/page/PageGridItem";
import useWindowDimensions, { analytics, downloadDocuments, onDocumentClick, usePdf, useRemote, useStorage } from "../../../utils/Utils";
import Button from "../../buttons/Button";
import LoadingIcon from "../../misc/LoadingIcon";
import { getStyle, getValue } from "../../renderer/DataController";
import FixedLabel from "../../renderer/FixedLabel";
import OptionalRowTable from "../../renderer/OptionalRowTable";
import SimpleTable from "../../renderer/SimpleTable";
import SlateEditor from "../../renderer/SlateEditor";
import TitledField from "../../renderer/TitledField";
import YearlyTable from "../../renderer/YearlyTable";
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import EditableLabel from "../../renderer/EditableLabel";
import "./ResearchViewer.scss";
import FlexWrapper from "../../misc/FlexWrapper";
import ResearchActionIcons from "../../buttons/ResearchActionIcons";
import CategoryChart from "../../renderer/CategoryChart";
import Divider from "../../renderer/Divider";
import Disclaimer from "./Disclaimer";
import Image from "../../renderer/Image";
import StoredFieldOnly from "../../renderer/StoredFieldOnly";
import FlexiDataTable from "../../renderer/FlexiDataTable";
import PerTickerTable from "../../renderer/PerTickerTable";
import SelectableText from "../../renderer/SelectableText";
import { maxHeight } from "@mui/system";
import ErrorBoundary from "../ErrorBoundary";
import LoginMessageWrapper from "../../../login/components/LoginMessageWrapper";
import PDFViewer from "./PDFViewer";

export const Viewer = ({ publication, fullScreenResearch, setDocHeight, setIsPdf, fillScreen }) => {

	const [pdf, setPdf] = useState(null);
	const [docId, setDocId] = useState(null);
	const [publishDto, setPublishDto] = useState(null);
	const [template, setTemplate] = useState(null);
	const [failed, setFailed] = useState(false);
	const [fontConfig, setFontConfig] = useState({});
	const [zoomStyle, setZoomStyle] = useState({ "transformOrigin": "top left", "transform": "scale(1)" });
	const [zoomVal, setZoomVal] = useState(1);
	const [numFlowingPages, setNumFlowingPages] = useState(0);
	const [loading, setLoading] = useState(true);
	const [loadingRefs, setLoadingRefs] = useState(true)
	const [loadingTemplate, setLoadingTemplate] = useState(true)
	const [flowingPageRendered, setFlowingPageRendered] = useState(false);

	const { width } = useWindowDimensions();
	const remote = useRemote();

	const totalNoteHeightRef = useRef()
	const flowingPageRefs = useRef({});
	const PageRefs = useRef([])

	const pageWidth = 595;	//FIXME - this needs to come from the template.
	const padding = 20;
	const isMobile = width < 768;

	useEffect(() => {
		let availableWidth = width - (padding * 4.5);
		if (availableWidth > 750) {
			availableWidth -= 200;
		}
		let ptsToPxls = pageWidth * 96 / 72;
		//remove padding
		ptsToPxls -= 100;
		let zoom = (availableWidth / ptsToPxls);
		//max zoom is 1.3x.
		const maxZoom = fillScreen ? 1.3 : 1.3
		if (zoom > maxZoom) {
			zoom = maxZoom;
		}
		// min zoom is 0.35x
		if (zoom < 0.35) {
			zoom = 0.35;
		}
		setZoomStyle(
			fillScreen && zoom > 1
				? { "transformOrigin": "top left", "transform": `scale(${zoom})`, "maxWidth": 100 / zoom + "vw" }
				: { "transformOrigin": "top left", "transform": `scale(${zoom})` }
		);
		setZoomVal(zoom)
		if (setDocHeight && totalNoteHeightRef.current && totalNoteHeightRef.current.clientHeight) {
			setDocHeight(totalNoteHeightRef.current.clientHeight * zoom);
		}
	}, [width, totalNoteHeightRef.current, template])

	const observer = new IntersectionObserver((entries, ignored) => {
		const entry = entries[0];

		const allPages = document.querySelectorAll('.research-viewer-page');
		const index = Array.from(allPages).indexOf(entry.target) + 1;

		entry.isIntersecting
			? analytics.track("pageIsVisible", { publication: publication.publicationId, page: index })
			: analytics.track("pageIsNotVisible", { publication: publication.publicationId, page: index })
	});

	// const fetchFlowingPagesCount = async (pages) => {
	// 	const counts = await Promise.all(pages.map(async (page) => {
	// 		if (page.type === "FLOWING" && docId) {
	// 			const resp = await remote.get(`/portal/publication/${docId}/flowing/${page?.flowContent?.dataName}`);
	// 			return resp.length;
	// 		} else {
	// 			return 1;
	// 		}
	// 	}));
	// 	return counts;
	// }

	const setPageRef = (ref, index) => {
		flowingPageRefs.current[index] = ref;
	};

	useEffect(() => {
		if (!template) {
			return;
		}
		setFontConfig({
			custom: {
				families: Object.keys(template.fonts),
				urls: Object.values(template.fonts).map((font) => font.importLink),
			},
		});

	}, [template]);

	useEffect(() => {

		if (numFlowingPages && template) {
			let refCreationArray = template.pages.map(page => page.type === "FLOWING" ? numFlowingPages : 1)
			PageRefs.current = refCreationArray.map((count, idx) => {

				return count > 1
					? { pageIdx: idx, count }
					: createRef();
			});
		}
		setLoadingRefs(false)
	}, [numFlowingPages, template && template.pages])

	useEffect(() => {
		setLoading(true);

		if (!publication || (!publication.publicationId && !publication.id)) {
			return;
		} else {
			let id = publication.publicationId || publication.id;
			let hasHtml = publication.hasHtmlVersion === undefined ? true : publication.hasHtmlVersion;
			setDocId(id);
			if (hasHtml) {
				remote
					.get(`/portal/publication/${id}`)
					.then((dto) => {
						if (dto) {
							setPublishDto(dto);
							const whichTemplate = dto.template;
							remote.get(`/portal/templates/${whichTemplate}`)
								.then((resp) => {
									setTemplate(resp)
									setFailed(false)
									setLoadingTemplate(false)
									setLoading(false);
								});
						} else {
							setLoading(false);
						}
					})
					.catch((e) => {
						console.error("Failed to load Research", e)
						setFailed(true);
						setPdf(true);
						setLoadingTemplate(false)
						setLoading(false);

					});
			} else {
				setLoading(false);
				setPdf(true)
			}
		}
	}, [publication && publication.publicationId]);

	useEffect(() => {
		const pages = document.querySelectorAll('.research-viewer-page');

		pages.forEach((r, idx) => {
			observer.observe(r, idx);
		})

		return () => {
			observer.disconnect();
		}


	}, [flowingPageRendered])

	const publicationDate = publication && (publication.publishedDate ? publication.publishedDate : publication.date);

	let id = publication && (publication.publicationId || publication.id);

	const defaultOutput = template && template.outputs && template.outputs.find(o => o.dflt).key;

	const errorFallback = (
		<div style={{
			// width: "595pt",
			// height: "100px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			// boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2)"
			paddingTop: "4rem"
		}}
		>
			<LoginMessageWrapper
				header=""
				message={
					<>
						<p>An error occurred while loading this note. </p>
						<a
							href="mailto:kathryn.warusevitane@craigsip.com?subject=Investor Portal - Trouble Logging In"
							target="_blank"
							className="text-link colour-text-primary"
						>
							Contact Us
						</a>
					</>
				}
			/>
		</div>
	)

	let currentPageNum = 0;
	let addFlowingPages = false;

	if (loading) {
		return <LoadingIcon centered={true} />
	}


	const viewer = failed || loadingRefs || loadingTemplate ? (
		pdf ? (
			<>
				<PDFViewer id={id} centered={fullScreenResearch} scale={zoomVal} />
			</>
		) : (
			<LoadingIcon centered={true} />
		)
	) : template && publishDto ? (
		<ErrorBoundary fallback={errorFallback}>
			<FlexWrapper id='actual-pages' ref={totalNoteHeightRef} className={`pl-xs${fullScreenResearch ? " research-fullscreen" : ""} zoom-style-print-control pb-m`} align="flex-start" direction="column" gap="l" style={zoomStyle}>
				{template.pages.map((p, idx) => {
					if (template.optionalPages && p.optional && publishDto.data && publishDto.data.OPTIONAL_PAGES && !publishDto.data.OPTIONAL_PAGES.includes(p.name)) {
						return null
					}
					if (p.type === 'PERTICKER') {
						currentPageNum += publishDto.tickers.length;
						return publishDto.tickers.map((t, perTickerIdx) => <Page pageNum={1 + currentPageNum - (publishDto.tickers.length - perTickerIdx) + (addFlowingPages ? numFlowingPages : 0)} setPageRef={setPageRef} pageID={idx} docId={id} forwardRef={PageRefs.current[idx]} key={idx + "-" + p.content.length + "-" + perTickerIdx} page={p} fontConfig={fontConfig} dto={publishDto} datasets={template.datasets} styles={template.styles} publishedDate={publicationDate} dataSuffix={t} />)
					}
					if (p.type === 'FLOWING') {
						addFlowingPages = true;
						return <Page pageNum={currentPageNum} setFlowingPageRendered={setFlowingPageRendered} setNumFlowingPages={setNumFlowingPages} setPageRef={setPageRef} pageID={idx} docId={id} ref={PageRefs.current[idx]} key={idx + "-" + p.content.length} page={p} fontConfig={fontConfig} dto={publishDto} styles={template.styles} datasets={template.datasets} publishedDate={publicationDate} defaultOutput={defaultOutput} />
					}
					currentPageNum += 1;
					return <Page pageNum={currentPageNum + (addFlowingPages ? numFlowingPages : 0)} setPageRef={setPageRef} pageID={idx} docId={id} ref={PageRefs.current[idx]} key={idx + "-" + p.content.length} page={p} fontConfig={fontConfig} dto={publishDto} styles={template.styles} datasets={template.datasets} publishedDate={publicationDate} defaultOutput={defaultOutput} />
				})}
				{/* Hardcoded disclaimer */}
				<Disclaimer />
			</FlexWrapper>
		</ErrorBoundary>
	) : (
		<LoadingIcon centered={true} />
	);

	return viewer;
};


const ResearchViewer = ({ publication, setSelectedResearch, compact, setFullScreenResearch, fullScreenResearch, refreshCurrentList, documentViewerOnly }) => {

	const remote = useRemote();
	const storage = useStorage();
	const [docHeight, setDocHeight] = useState(null)
	const [isPdf, setIsPdf] = useState(false)

	// const docHeightRef = useRef()

	useEffect(() => {
		if (!publication || !publication.publicationId) {
			return;
		}
		if (publication.read) {
			return;
		}
		remote.post("/portal/read/" + publication.publicationId)
			.then(() => {
				refreshCurrentList && refreshCurrentList()
				storage.refresh('/portal/dashboard')
			})
		publication.read = true;
	}, [publication]);

	const datafiles = publication && publication.models ? publication.models : [];
	const defaultPdf = (publication && publication.files && publication.files.filter(f => f.defaultPdf)) || [];
	const { width } = useWindowDimensions();

	let styleWithRef = docHeight ? { overflowY: "visible", maxHeight: (docHeight) + "px" } : { overflowY: "visible" }
	return (
		<PageGridItem col="1 / span 12">
			<PageContentBodyGrid id='whole-page' rowGap="xl" divider={!compact && "top"}>
				<PageGridItem col="1 / span 12">
					<FlexWrapper className="research-viewer-actions" fullWidth align="center" gap="l">
						<Button variant="secondary" onClick={() => setSelectedResearch(null)} disabled={documentViewerOnly} >View History</Button>
						<ResearchActionIcons
							hideFullscreen={width < 768 ? true : false}
							size={16}
							setFullScreenResearch={() => setFullScreenResearch(true)}
							downloadDisabled={defaultPdf.length === 0}
							downloadOnClick={(e) => { analytics.track("downloadPDF", { asset: publication.coveredShortName, publication }); downloadDocuments(e, defaultPdf) }}
							printDisabled={defaultPdf.length === 0 || isPdf}
							printOnclick={(e) => { analytics.track("printPDF", { asset: publication.coveredShortName, publication }); downloadDocuments(e, defaultPdf) }}
							dataDisabled={documentViewerOnly || publication?.models?.length === 0}
							dataOnClick={(e) => { analytics.track("downloadData", { asset: publication.coveredShortName, publication }); downloadDocuments(e, publication.models, true) }}
						/>
					</FlexWrapper>
					{/* <Button variant="secondary" onClick={() => setFullScreenResearch(true)}><Maximize2 /></Button> */}
				</PageGridItem>
				<PageGridItem col="1 / span 12" id='viewer-outer' style={styleWithRef}>

					<Viewer publication={publication} fullScreenResearch={fullScreenResearch} setDocHeight={setDocHeight} setIsPdf={setIsPdf} />
				</PageGridItem>
			</PageContentBodyGrid>
		</PageGridItem>
	)
}

export default ResearchViewer;

const ResearchComponent = ({ obj, defaultOutput, pageNum, dto, publishedDate, dataSuffix, styles, datasets }) => {

	const getDataFromDto = (key, internalSuffix) => {
		if (internalSuffix && internalSuffix != '') {
			return getValue(key, internalSuffix, dto.data, publishedDate, pageNum);
		}
		return getValue(key, dataSuffix ? "<" + dataSuffix + ">" : '', dto.data, publishedDate, pageNum);
	}

	const getJsonFromDto = (key) => {
		return dto.data[key]
			? JSON.parse(dto.data[key])
			: [{
				type: 'paragraph',
				children: [{ text: '' }],
			}]
	}

	const getLocalDataFromDto = (key) => {
		return dto.data[key + (dataSuffix ? "<" + dataSuffix + ">" : "")];

	}

	if (obj.applyTo && obj.applyTo !== null) {
		if (defaultOutput && !obj.applyTo.includes(defaultOutput)) {
			return null;
		}
	}
	switch (obj.className) {
		case 'FixedLabel':
			return <FixedLabel styles={styles} config={obj.configuration} getValue={getDataFromDto} getStyle={getStyle} />
		case 'EditableLabel':
			return <EditableLabel styles={styles} config={obj.configuration} getValue={(key) => dto.data[key]} getStyle={getStyle} />
		case 'EditableText':
			return <SlateEditor name={obj.configuration.dataName} staticConfig={obj.configuration} load={getJsonFromDto(obj.configuration.dataName)} styleDefs={obj.configuration.styles} styles={styles} getStyle={getStyle} />
		case 'TitledField':
			return <TitledField styles={styles} config={obj.configuration} title={getDataFromDto(obj.configuration.title.value)} value={getDataFromDto(obj.configuration.value.value)} />
		case 'SimpleTable':
			return <SimpleTable styles={styles} configuration={obj.configuration} getData={getDataFromDto} />
		case 'YearlyTable':
			return <YearlyTable styles={styles} config={obj.configuration} getData={getDataFromDto} />
		case 'OptionalRowTable':
			return <OptionalRowTable styles={styles} configuration={obj.configuration} getLocalValue={getLocalDataFromDto} getValue={getDataFromDto} getStyle={getStyle} />
		case 'CategoryChart':
			return <CategoryChart styles={styles} config={obj.configuration} dto={dto} getStyle={getStyle} />
		case 'Divider':
			return <Divider styles={styles} config={obj.configuration} />
		case 'Image':
			return <Image styles={styles} config={obj.configuration} />
		case 'FlexiDataTable':
			return <FlexiDataTable styles={styles} config={obj.configuration} getValue={getDataFromDto} getStyle={getStyle} />
		case 'PerTickerTable':
			return <PerTickerTable styles={styles} config={obj.configuration} getValue={getDataFromDto} getStyle={getStyle} tickers={dto.tickers} />
		case 'SelectableText':
			return <SelectableText styles={styles} config={obj.configuration} datasets={datasets} getValue={getLocalDataFromDto} getStyle={getStyle} />
		case 'StoredDataField':
			break;
		default:
			return null;
	}
}


const Page = forwardRef(({ page, dto, styles, fontConfig, pageID, publishedDate, dataSuffix, docId, datasets, defaultOutput, setPageRef, setFlowingPageRendered, setNumFlowingPages, pageNum }, ref) => {

	const components = page.content;

	const viewPortWidth = useWindowDimensions().width;
	const isMobile = viewPortWidth < 768;

	const getDataFromDto = (key, internalSuffix) => {
		if (internalSuffix && internalSuffix != '') {
			return getValue(key, internalSuffix, dto.data, publishedDate, pageNum);
		}
		return getValue(key, dataSuffix ? "<" + dataSuffix + ">" : '', dto.data, publishedDate, pageNum);
	}

	const getJsonFromDto = (key) => {
		return dto.data[key]
			? JSON.parse(dto.data[key])
			: [{
				type: 'paragraph',
				children: [{ text: '' }],
			}]
	}

	const getLocalDataFromDto = (key) => {
		return dto.data[key + (dataSuffix ? "<" + dataSuffix + ">" : "")];

	}

	const component = (obj) => {
		if (obj.applyTo && obj.applyTo !== null) {
			if (defaultOutput && !obj.applyTo.includes(defaultOutput)) {
				return null;
			}
		}
		switch (obj.className) {
			case 'FixedLabel':
				return <FixedLabel styles={styles} config={obj.configuration} getValue={getDataFromDto} getStyle={getStyle} />
			case 'EditableLabel':
				return <EditableLabel styles={styles} config={obj.configuration} getValue={(key) => dto.data[key]} getStyle={getStyle} />
			case 'EditableText':
				return <SlateEditor name={obj.configuration.dataName} staticConfig={obj.configuration} load={getJsonFromDto(obj.configuration.dataName)} styleDefs={obj.configuration.styles} styles={styles} getStyle={getStyle} />
			case 'TitledField':
				return <TitledField styles={styles} config={obj.configuration} title={getDataFromDto(obj.configuration.title.value)} value={getDataFromDto(obj.configuration.value.value)} />
			case 'SimpleTable':
				return <SimpleTable styles={styles} configuration={obj.configuration} getData={getDataFromDto} />
			case 'YearlyTable':
				return <YearlyTable styles={styles} config={obj.configuration} getData={getDataFromDto} />
			case 'OptionalRowTable':
				return <OptionalRowTable styles={styles} configuration={obj.configuration} getLocalValue={getLocalDataFromDto} getValue={getDataFromDto} getStyle={getStyle} />
			case 'CategoryChart':
				return <CategoryChart styles={styles} config={obj.configuration} dto={dto} getStyle={getStyle} />
			case 'Divider':
				return <Divider styles={styles} config={obj.configuration} />
			case 'Image':
				return <Image styles={styles} config={obj.configuration} />
			case 'FlexiDataTable':
				return <FlexiDataTable styles={styles} config={obj.configuration} getValue={getDataFromDto} getStyle={getStyle} />
			case 'PerTickerTable':
				return <PerTickerTable styles={styles} config={obj.configuration} getValue={getDataFromDto} getStyle={getStyle} tickers={dto.tickers} />
			case 'SelectableText':
				return <SelectableText styles={styles} config={obj.configuration} datasets={datasets} getValue={getLocalDataFromDto} getStyle={getStyle} />
			case 'StoredDataField':
				break;
			default:
				return null;
		}
	}

	if (page.type === 'FLOWING') {
		return <FlowContent
			setFlowingPageRendered={setFlowingPageRendered}
			dto={dto}
			setNumFlowingPages={setNumFlowingPages}
			content={page.content}
			docId={docId}
			setPageRef={setPageRef}
			pageNum={pageNum}
			forwardRef={ref}
			pageID={pageID}
			pageWidth={page.size.width}
			config={page.flowContent}
			styles={styles}
			load={getJsonFromDto(page.flowContent.dataName)}
		>

		</FlowContent>
	}



	const height = (page.type === 'FIXED' || page.type === 'PERTICKER') ? page.size.height + 'pt' : 'inherit';
	const width = (page.type === 'FIXED' || page.type === 'PERTICKER') ? page.size.width + 'pt' : 'inherit';

	return (
		<WebfontLoader onStatus={() => { }} config={fontConfig}>
			<div id={"research-page-" + pageID} ref={ref} className="research-viewer-page" style={{ position: "relative", height: (height), width: width }}>{components.map((obj, idx) => <AbsoluteComponent key={idx} width={obj.configuration.width} x={obj.location.left} y={obj.location.top}>
				{component(obj)}
			</AbsoluteComponent>)}
			</div>
		</WebfontLoader>
	)
})

const AbsoluteComponent = (props) => {

	//dying on spread operator inside Eikon, so changed to not use.
	const otherStyles = props.style || {};
	otherStyles.position = "absolute";
	otherStyles.left = props.x + 'pt';

	if (props.width) {
		otherStyles.width = props.width + 'pt';
	}

	if (props.relativeBottom) {
		otherStyles.bottom = props.bottom;
	} else {
		otherStyles.top = props.y + 'pt';
	}

	return <div style={otherStyles}>{props.children}</div>
}

const FlowContent = ({ config, styles, load, content, pageNum, pageWidth, ref, pageID, docId, setPageRef, flowing, setFlowingPageRendered, setNumFlowingPages, children, dto }) => {
	const [pages, setPages] = useState([]);
	const field = config.dataName;
	const remote = useRemote();

	useEffect(() => {
		if (docId) {
			remote
				.get(`/portal/publication/${docId}/flowing/${field}`).then((pages) => {
					setPages(pages);

				});
		}
	}, [docId])

	useEffect(() => {
		if (pages.length) {
			setFlowingPageRendered(true);
			setNumFlowingPages(pages.length)
		}
	}, [pages])


	let margins = {
		"top": 100,
		"right": 144,
		"bottom": 72,
		"left": 36
	}

	if (config && config.margins) {
		if (config.margins.top) {
			margins.top = config.margins.top;
		}
		if (config.margins.right) {
			margins.right = config.margins.right;
		}
		if (config.margins.bottom) {
			margins.bottom = config.margins.bottom;
		}
		if (config.margins.left) {
			margins.left = config.margins.left;
		}
	}

	if (pages.length == 0) {

		return <div style={{ width: pageWidth + 'pt' }}>
			<LoadingIcon centered={true} size={60} />

		</div>
	}

	const currentRef = 1;

	return <>{
		pages.map((page, idx) => {
			return <div key={"page" + idx} className="research-viewer-page flow-page" >
				<div
					style={{ marginTop: margins.top + 'pt', marginLeft: margins.left + 'pt', marginRight: margins.right + 'pt', marginBottom: margins.bottom + 'pt' }}
					ref={ref => {
						if (ref !== currentRef) {
							setPageRef(ref, idx);
						}
					}}
					id={pageID}
				>
					<SlateEditor name="flowingPage" load={page} styleDefs={config.styles} styles={styles} flowingConfig={config} />
					{content.map((obj, componentIdx) => {
						return (<AbsoluteComponent key={componentIdx + "_" + idx} width={obj.configuration.width} x={obj.location.left} y={obj.location.top}>
							<ResearchComponent obj={obj} pageNum={pageNum + idx + 1} dto={dto} styles={styles} />
						</AbsoluteComponent>)
					}
					)}
					{children}
				</div>
			</div>
		})
	}</>

}
