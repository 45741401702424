import styled from "@emotion/styled";
import { getValue } from "@testing-library/user-event/dist/utils";
import React, { Component } from "react";
import { getStyle } from "./DataController";
import "./SimpleTable.scss";

const SimpleTable = (props) => {

	const {styles, configuration, getData} = props;
	
	const {columnWidths, cells} = configuration;

	const {header, footer} = configuration;

	const otherRowStyle = {};
	
	const baseStyle = getStyle(styles[configuration.defaultCellStyle], true);
	const tableWidth = configuration.columnWidths.reduce((p,t)=>p+t);
	let { headerRows, /*headerColumns,*/ rowStyles, cellStyles, columnStyles, tableStyles } = configuration;
	headerRows = headerRows || [];
	
	const headerStyle = configuration.headerStyle ? getStyle(styles[configuration.headerStyle]) : null;
	const footerStyle = configuration.footerStyle ? getStyle(styles[configuration.footerStyle])  : null;
	if (footerStyle)
		footerStyle.paddingTop = configuration.cellVerticalPadding + "pt";

	const tableStyle = {
		width: tableWidth+'pt'
	};
	if (tableStyles) {
		const lookup = getStyle(styles[tableStyles], true);
		Object.assign(tableStyle, lookup);
	}


	return <div>
	{/* Header */}
	{header && (
		<div className="table-header" style={headerStyle}>
			{getData(header.value)}
		</div>
	)}
	{/* Table */}
	<table style={tableStyle}>
		<tbody>
			{cells.map((tableRow, trIndex) => {
				const isHeader = headerRows.includes(trIndex);
				let rowStyle = (rowStyles && rowStyles[trIndex] && getStyle(styles[rowStyles[trIndex]], true)) || {};
				if (otherRowStyle && otherRowStyle[trIndex]) {
					rowStyle = { ...rowStyle, ...otherRowStyle[trIndex] };
				}
				const heightStyle = Object.assign({}, baseStyle, rowStyle);
				let height = heightStyle.height;
				const heightProp = {};
				if (height) { 
					heightProp.height = height;
				}
				return <tr key={trIndex}>
					{tableRow.map((tableData, tdIndex) => {
						const colStyle = columnStyles && columnStyles[tdIndex] && getStyle(styles[columnStyles[tdIndex]], true);

						const cellStyle = cellStyles && cellStyles[trIndex] && cellStyles[trIndex][tdIndex];
						const tdStyle = {};
						if (cellStyle) {
							if (cellStyle.includes("BOLD")) {
								tdStyle.fontWeight = "bold";
							}
							if (cellStyle.includes("ITALIC")) {
								tdStyle.fontStyle = "italic";
							}
						}
						if (isHeader) {
							Object.assign(tdStyle, baseStyle, colStyle, rowStyle);
						} else {
							Object.assign(tdStyle, baseStyle, rowStyle, colStyle);
						}
						Object.assign(tdStyle, {
							width: configuration.columnWidths[tdIndex]+"pt",
							textAlign: configuration.columnJustify[tdIndex]
						}, heightProp);

						return (
							<td
								style={tdStyle}
								key={tdIndex}
							>
								{getData(tableData.value)}
							</td>
						)
					})}
				</tr>
			})}
		</tbody>
	</table>
	{/* Footer */}
	{footer && (
		<div style={footerStyle} className="table-source-text">
			{getData(footer.value)}
		</div>
	)}
	</div>
	
}

export default SimpleTable;
