import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AnalyticsProvider } from 'use-analytics';
import './App.scss';
import './styles/UtilClasses.scss';
import LoginWrapper from './login/LoginWrapper';
import LoginPage from './login/pages/LoginPage';
import ForgotPasswordPage from './login/pages/ForgotPasswordPage';
import SetPasswordPage from './login/pages/SetPasswordPage';
import AnalystsPage from './portal/pages/analysts/AnalystsPage';
import AnalystDetailsPage from './portal/pages/analysts/AnalystDetailsPage';
import HomePage from './portal/pages/home/HomePage';
import LibraryPage from './portal/pages/library/LibraryPage';
import SettingsPage from './portal/pages/settings/SettingsPage';
import PortalWrapper from './portal/PortalWrapper';
import { analytics, useRemote, useStorage } from './utils/Utils';
import CompanyPage from './portal/pages/company/CompanyPage';
import PublicationViewerPage from './portal/pages/publicationViewer/PublicationViewerPage';
import ErrorPage from './portal/pages/error/ErrorPage';
import AlertBanner from './components/shared/prompts/AlertBanner';
import SectorPage from './portal/pages/sector/SectorPage';
import ScrollTop from './components/misc/ScrollTop';
import ToastContainer from './components/popups/ToastContainer';
import { isBefore, parseISO } from 'date-fns';
import moment from 'moment';
import LoadingIcon from './components/misc/LoadingIcon';


function App() {

	const [loggedIn, setLoggedIn] = useState(sessionStorage.getItem("3skye.loggedin") === "true");
	const [me, setMe] = useState(null);
	const [loading, setLoading] = useState(false);

	const remote = useRemote();
	const storage = useStorage();

	useEffect(() => {
		if (loggedIn) {
			remote.get("/portal/me").then((resp) => {
				setMe(resp)
			}).catch(err => {
				console.log("Got error from me ", err)
			});
		}
	}, [loggedIn])

	useEffect(() => {
		if (!loggedIn) {
			return;
		}
		let token = sessionStorage.getItem("3skye.auth.token")
		me && token && analytics.identify(me)
	}, [me])

	const doLogin = () => {
		sessionStorage.setItem("3skye.loggedin", true);
		setLoggedIn(true);
	}

	useEffect(() => {
		remote.registerLogoutHandler(logout)
	}, [remote])


	const logout = () => {
		sessionStorage.setItem("3skye.loggedin", "false");
		localStorage.removeItem("3skye.persistent.token")
		localStorage.removeItem("3skye.persistent.expiry")
		setLoggedIn(false);
	}

	if (loading) {
		return <LoadingIcon centered size={48}/>
	}

	return (
		<AnalyticsProvider instance={analytics}>
			<div className="App">
				<div className={loggedIn ? "" : " login-page"}>
					<ToastContainer />
					<BrowserRouter>
						{/* Pages */}
						<ScrollTop>
							<Routes>
								{/* Login pages */}
								{loggedIn ? (
									<Route path="/" element={<PortalWrapper me={me} />}>
										<Route index element={<HomePage me={me} />} />
										<Route path="library" element={<LibraryPage />} />
										<Route path="company/:ticker" element={<CompanyPage />} />
										<Route path="company/:ticker/:publication" element={<CompanyPage />} />
										<Route path="document/:publication" element={<PublicationViewerPage documentViewerOnly />} />
										<Route path="group/:grouping/:group" element={<SectorPage />} />
										<Route path="group/:grouping/:group/:publication" element={<SectorPage />} />
										{/* 										<Route path="sector/:group" element={<SectorPage />} />
										<Route path="sector/:group/:publication" element={<SectorPage />} /> */}
										<Route path="analysts" element={<AnalystsPage />} />
										<Route path="analysts/:analystId" element={<AnalystDetailsPage />} />
										<Route path="settings" element={<SettingsPage user={me} />} />
										{/* Error page below needs wiring. */}
										<Route path="*" element={<ErrorPage />} />
										<Route path="/page-not-found" element={<ErrorPage />} />
									</Route>
								) : (
									<Route path="/" element={<LoginWrapper />}>
										<Route index element={<LoginPage setLoggedIn={doLogin} />} />
										<Route path="forgot-password" element={<ForgotPasswordPage />} />
										<Route path="first-time-user" element={<ForgotPasswordPage firstTimeUser />} />
										<Route path="set-password" element={<SetPasswordPage header="Set Password" />} />
										{/* <Route path="view/:campaign" element={<PublicResearchViewer />}/> */}
										<Route path="*" element={<LoginPage setLoggedIn={doLogin} />} />
									</Route>
								)}
							</Routes>
						</ScrollTop>
					</BrowserRouter>
				</div>
			</div>
		</AnalyticsProvider>
	);
}

export default App;
