import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/buttons/Button';
import TextInput from '../../components/inputs/TextInput';
import { analytics, useRemote } from '../../utils/Utils';
import LoginMessageWrapper from '../components/LoginMessageWrapper';
import LoginSubText from '../components/LoginSubText';
import LoginTextButton from '../components/LoginTextButton';
import { useCookies, withCookies } from 'react-cookie';
import LoadingIcon from '../../components/misc/LoadingIcon';
import FormAlertMessage from '../../components/shared/prompts/FormAlertMessage';
import TwoFactorAuthPage from './TwoFactorAuthPage';
import FlexWrapper from '../../components/misc/FlexWrapper';
import Checkbox from '../../components/inputs/Checkbox';
import { addDays, formatISO, isBefore, parseISO } from 'date-fns';

const LoginPage = ({ setLoggedIn, cookies: browserCookies }) => {

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const [twoFAError, set2FAError] = useState("");
	const [twoFARequired, setTwoFARequired] = useState(false);
	const [code, setCode] = useState("")
	const [twoFAKey, setTwoFAKey] = useState("")
	const [loggingIn, setLoggingIn] = useState(true)
	const [rememberMe, setRememberMe] = useState(false)

	const [, setCookie] = useCookies([])

	const remote = useRemote();

	const navigate = useNavigate();

	useEffect(() => {
		document.body.style.overflowY = "unset";
		if (localStorage.getItem("3skye.persistent.token")) {
			setError("");

			let token = localStorage.getItem("3skye.persistent.token")
			let expiry = localStorage.getItem("3skye.persistent.expiry")

			if (isBefore(parseISO(expiry), new Date())) {
				setLoggingIn(false);

				localStorage.removeItem("3skye.persistent.token")
				localStorage.removeItem("3skye.persistent.expiry")
			} else {
				const data = { "username": token }
				remote.post("/um/validate", data, { rawResponse: true }).then(async raw => {
					let resp = await raw.json()
					let sessionToken = raw.headers.get("X-3Skye-Set-Token")
					return [resp, sessionToken]
				}).then(([resp, sessionToken]) => {
					if (resp && resp.success) {
						analytics.track("LogIn")
						const stored = sessionStorage.getItem('3skye.auth.token')
						setLoggedIn(true);
					}
				}).catch(err => {
					console.log(err);
					setLoggingIn(false)
				})
			}
		} else { setLoggingIn(false) }
		return () => {
			// Remove the style when the component unmounts
			document.body.style.overflowY = "initial"; // Restore the default value
		  };
	}, [])

	const getLoginErrorMessage = (message) => {
		switch (message) {
			case "account-locked":
				return "Your account has been locked due to repeated failed log in attempts. Please wait a few minutes and try again."
			case "no-portal-access":
			default:
				return "Could not log you in. Please check your username and password and try again."
		}
	}

	const doLogin = () => {
		setLoggingIn(true)
		setError("");
		const data = { username, password }
		const twofa = has2fa();
		if (twofa) {
			data.twoFAToken = twofa;
		}
		remote.post("/um/login", data, { rawResponse: true }).then(async raw => {
			let resp = await raw.json()
			let sessionToken = raw.headers.get("X-3Skye-Set-Token")
			return [resp, sessionToken]
		}).then(([resp, sessionToken]) => {
			if (resp && resp.success) {
				analytics.track("LogIn")
				if (rememberMe) {
					localStorage.setItem("3skye.persistent.token", sessionToken)
				}
				setLoggedIn(true);
			} else {
				switch (resp.message) {
					case "requires2FA":
						setTwoFAKey(resp.data.key)
						setTwoFARequired(true)
						break;
					default:
						setError(getLoginErrorMessage(resp.message));
						break;
				}
			}
			setLoggingIn(false)

		}).catch(e => {
			setLoggingIn(false)
			setError("Could not log you in. Please check your username and password and try again.");
		})
	}

	const has2fa = () => {
		let user = username.replace("@", "#");
		const cookie = browserCookies.get('3skye.login.' + user);
		if (typeof cookie === "undefined") {
			return false;
		}
		return cookie;
	}

	const handle2FA = (code) => {
		const data = {
			username: username,
			code,
			key: twoFAKey,
		};

		remote.post("/um/validate-credentials-2fa", data).then(
			(resp) => {
				if (resp.status === "valid") {
					const cookieValue = has2fa();//resp.cookie;
					let user = username.replace("@", "#");
					const oneYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
					setCookie("3skye.login." + user, cookieValue, { path: "/", expires: oneYear, secure: true });

					const data2 = {
						username: username,
						password: password,
						twoFAToken: cookieValue,
						source: "tomato"
					};

					remote.post("/um/customer-portal/login", data2, { rawResponse: true }).then(
						(x) => {
							setLoggedIn(true)
						},
						(x) => {
							setTwoFAKey("");
							set2FAError("Could not log you in. Please check your username and password and try again.");
						}
					);
				}
			},
			(x) => {
				set2FAError("Oops, that code wasn't right.  Please try again");
			}
		);
	}

	const handleLoginKeyPress = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			if (twoFARequired) {
				handle2FA()
			} else {
				doLogin()
			}
		}
	}

	if (!twoFARequired) {
		return (
			<div id="login-page">
				<LoginMessageWrapper
					header="Welcome"
					message={
						<>
							Welcome to our institutional research library, please sign in below. If you want to set up an account or are having trouble logging in, please contact
							us{" "}
							<a
								href="mailto:kathryn.warusevitane@craigsip.com?subject=Investor Portal - Trouble Logging In"
								target="_blank"
								className="text-link colour-text-primary"
							>
								here
							</a>
						</>
					}
				/>
				{/* Fields */}
				<TextInput
					invalid={error}
					autoFocus
					onKeyDown={(e) => handleLoginKeyPress(e)}
					className="mb-l"
					label="Email Address"
					placeholder="Jane@example.com"
					value={username}
					onChange={(e) => setUsername(e.target.value)}
				/>
				<TextInput
					invalid={error}
					onKeyDown={(e) => handleLoginKeyPress(e)}
					className="mb-l"
					label="Password"
					placeholder="Password"
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
				/>
				{error && <FormAlertMessage inlineIcon fullWidth type="danger" message={error} />}
				<Button disabled={loggingIn} large fullWidth className="mb-xl" onClick={doLogin}>
					<div className="font-weight-semi-bold">
						{loggingIn ? (
							<div style={{ transform: "translateY(-12px)" }}>
								<LoadingIcon variant="light" centered size={24} />
							</div>
						) : (
							"Login"
						)}
					</div>
				</Button>
				<FlexWrapper justify="space-between" direction="row" align="center" className="mb-xl">
					<Checkbox label="Remember Me" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
					<LoginTextButton inline onClick={() => navigate("/forgot-password")} className="m-none">Forgot Password</LoginTextButton>
				</FlexWrapper>
				{/* <LoginSubText>Having trouble logging in? Please contact your CIP sales rep or email <a href="mailto:kathryn.warusevitane@craigsip.com?subject=Investor Portal - Trouble Logging In" target="_blank" className="text-link colour-text-primary">kathryn.warusevitane@craigsip.com</a>.</LoginSubText> */}
				<LoginSubText margin="m">
					By accessing our client portal you agree to our{" "}
					<a href="https://craigsip.com/terms-and-conditions" className="text-link colour-text-primary">
						Terms of Use
					</a>
				</LoginSubText>
			</div>
		);
	}
	return (
		<div id="forgot-pw-code-page">
			<TwoFactorAuthPage
				onKeyDown={(e) => handleLoginKeyPress(e)}
				value={code}
				onChange={(e) => setCode(e.target.value)}
				onSubmit={(e) => handle2FA(e.target.value)}
			/>
			<LoginTextButton onClick={() => setTwoFARequired(false)}>Back to login page</LoginTextButton>
		</div>
	)
}

export default withCookies(LoginPage);