import React from 'react';
import { CheckCircle } from 'react-feather';
import Tooltip from '../popups/Tooltip';
import { analytics, useRemote } from '../../utils/Utils';
import "./WatchlistIcon.scss";

const WatchlistIcon = ({ onWatchList, size, label, onClick, assetId, style, grouping, path }) => {

	const remote = useRemote();

	const identifier = path ? path : assetId;

	const handleWatchListClick = (e) => {
		console.log("Handling a watchlist click", onWatchList, grouping, identifier)
		e.stopPropagation()
		switch (onWatchList) {
			case true:
				analytics.track("removeWatchList", { ticker: assetId })
				remote.put(`/portal/watch/${identifier}/${grouping ? grouping : "tickers"}`, {watch:false}).then(onClick)
				break;
			case false:
			case undefined:
				analytics.track("addWatchList", { ticker: assetId })
				remote.put(`/portal/watch/${identifier}/${grouping ? grouping : "tickers"}`, {watch:true}).then(onClick)
				break;

			default:
				break;
		}
		
	}

	return (
		<Tooltip label={label ? label : onWatchList ? "Remove from watchlist" : "Add to watchlist"}><CheckCircle className={`watchlist-icon cursor-pointer ${onWatchList ? "colour-text-positive-dark" : "colour-text-icon-grey colour-hover-text-neutral-dark-60"}`} style={style} size={size} onClick={(e) => handleWatchListClick(e)} /></Tooltip>
	);
}

export default WatchlistIcon;