import React, { useEffect, useState, useMemo } from "react";
import TickerListSearchField from "../../../components/inputs/TickerListSearchField";
import Divider from "../../../components/misc/Divider";
import { PageContentBanner } from "../../../layout/grid/page/Page";
import { debounce } from 'throttle-debounce'
import { withLibraryDocsFilters } from "./withLibraryDocs";
import useWindowDimensions, { analytics, useStorage } from "../../../utils/Utils";
import LoadingIcon from "../../../components/misc/LoadingIcon";
import FlexWrapper from "../../../components/misc/FlexWrapper.js"
import moment from "moment";
import DateRange from "../../../components/inputs/DateRange";

const LibraryPageContentBanner = ({ setLibraryDocsFilters, libraryDocsFilters }) => {
	const [tickersLoading, setTickersLoading] = useState(true)
	const [tickers, setTickers] = useState([])
	const { width, height } = useWindowDimensions();
	const isMobile = width < 768;

	const storage = useStorage()

	useEffect(() => {
		storage.getOrFetch("/portal/library/search-options")
			.then(setTickers)
			.then(() => setTickersLoading(false))
	}, [storage])

	const debouncedUpdateDateFilter = useMemo(() => debounce(
		500,
		(innerScopeFrom, innerScopeTo) => {
			setLibraryDocsFilters({
				ticker: libraryDocsFilters.ticker,
				from: moment(innerScopeFrom).isValid() ? moment(innerScopeFrom).format("yyyy-MM-DD") : null,
				to: moment(innerScopeTo).isValid() ? moment(innerScopeTo).format("yyyy-MM-DD") : null
			})
			analytics.track("libraryDateSearch", {
				from: moment(innerScopeFrom).isValid() ? moment(innerScopeFrom).format("yyyy-MM-DD") : null,
				to: moment(innerScopeTo).isValid() ? moment(innerScopeTo).format("yyyy-MM-DD") : null
			})
		}
		), [])
		
		return (
			<PageContentBanner divider direction="column" gap={"s"} mobile={width >= 900} style={{ position: "sticky", top: (width >= 900 ? "-32px" : height < 490 ? "-47px" : "-89px"), zIndex: 11, backgroundColor: "#fff" }}>
			<h2>
				Library
			</h2>
			<p style={{margin: 0}} className="banner-subheader colour-text-label-grey">Search here for our past research. Models may be accessed for selected stocks by clicking on the Excel icon.</p>
			<Divider />
			<FlexWrapper direction={isMobile ? "column" : "row"} gap={"m"} wrap fullWidth>
				<div id='library-search-wrapper' style={{ width: isMobile ? "100%" : 300 }}>
					{tickersLoading
						? <LoadingIcon size={24} centered />
						: <TickerListSearchField
						size="small"
						placeholder="Enter ticker: SPK.NZ"
						isClearable
						value={libraryDocsFilters.ticker}
						onChange={(ticker) => {
							setLibraryDocsFilters({ ...libraryDocsFilters, ticker })
							analytics.track("librarySearch", ticker)
						}}
						tickerList={tickers}
						onClear={() => setLibraryDocsFilters({ ...libraryDocsFilters, ticker: null })}
						/>}
				</div>
				<DateRange
					from={libraryDocsFilters.from}
					to={libraryDocsFilters.to}
					onFromChange={(from) => {
						debouncedUpdateDateFilter(from, libraryDocsFilters.to)
					}}
					onToChange={(to) => {
						debouncedUpdateDateFilter(libraryDocsFilters.from, to)
					}}
					onClear={() => {
						setLibraryDocsFilters({
							ticker: libraryDocsFilters.ticker,
							from: null,
							to: null
						})
					}}
				/>
			</FlexWrapper>
		</PageContentBanner>
	);
}

export default withLibraryDocsFilters(LibraryPageContentBanner);
