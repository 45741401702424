import React from "react";

const EditableLabel = (props) => {

	const {config, styles, getStyle, getValue} = props;

	return <p style={{...getStyle(styles[config.style]), whiteSpace: "nowrap"}}>{getValue(config.dataName)}</p>
}

export default EditableLabel;
