import React from 'react';
import WatchlistIcon from '../icons/WatchlistIcon';
import placeholder from "../../resources/images/sectors/research-2.png";
import TextInput from '../inputs/TextInput';
import Tooltip from '../popups/Tooltip';
import ExtensionLabel from './ExtensionLabel';
import FlexWrapper from './FlexWrapper';
import TickerSectorImage from './TickerSectorImage';
import "./TickerSectorItem.scss";
import UnreadIndicator from '../icons/UnreadIndicator';
import IconButton from '../buttons/IconButton';
import { Info } from 'react-feather';
import InfoIcon from '../buttons/InfoIcon';

const TickerSectorItem = ({
	label,
	extensionLabel,
	extensionTooltip,
	size,
	image,
	tooltip,
	hoverUpload,
	hoverIcon,
	imageOnClick,
	isMobile,
	onWatchlist,
	unread,
	onClick,
	infoIcon,
	compactView,
	infoIconOnClick,
	hideWatchlistIcon,
	assetId,
	grouping,
	path,
	hideExtras
}) => {

	return (
		<Tooltip className={`ticker-sector-tooltip${size ? " tsi-size-" + size : ""}${isMobile ? " tsi-mobile" : ""}`} disabled={!tooltip} label={tooltip}>
			<div className={`ticker-sector-item${size ? " tsi-size-" + size : ""} ${hideExtras ? "tsi-align-left" : ""}`}>
				{/* image/logo */}
				<TickerSectorImage hoverUpload={hoverUpload} hoverIcon={hoverIcon} image={image} placeholder={placeholder} size={size === "xl" ? "l" : size} onClick={imageOnClick} />
				<div className="tsi-details">
					{unread && <UnreadIndicator size={6} className="mr-xs" />}
					<div className={`tsi-label${unread ? " font-weight-medium" : ""}`}>{label ? label : "-"}</div>
					{extensionLabel && !extensionTooltip ? (
						<ExtensionLabel className="tsi-sub-label">{extensionLabel}</ExtensionLabel>
					) : (
						extensionLabel &&
						extensionTooltip && (
							<Tooltip label={extensionTooltip}>
								<ExtensionLabel className="tsi-sub-label">{extensionLabel}</ExtensionLabel>
							</Tooltip>
						)
					)}
				</div>
				{!hideExtras && (size === "xl" || isMobile) && (
					<>
						{infoIcon && <InfoIcon className={hideWatchlistIcon ? "ml-none" : "ml-m"} active={!compactView} onClick={infoIconOnClick} />}
						{!hideWatchlistIcon && <WatchlistIcon size="24" onWatchList={onWatchlist} assetId={assetId} onClick={onClick} grouping={grouping} path={path} />}
					</>
				)}
			</div>
		</Tooltip>
	);
};

export default TickerSectorItem;