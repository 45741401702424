import React, { useEffect } from 'react';
import { ExternalLink, Maximize2, Printer } from 'react-feather';
import XlsIcon from '../icons/XlsIcon';
import TableActionIcon from '../lists-and-tables/TableActionIcon';
import FlexWrapper from '../misc/FlexWrapper';
import "./ResearchActionIcons.scss";

const DownloadIcon = ({onClick, size, disabled}) => {
	return <TableActionIcon disabled={disabled} className="research-action-icon download" icon={<ExternalLink size={size} />} onClick={onClick} toolTip={"Download PDF"}/>
}

const PrintIcon = ({onClick, size, disabled, id}) => {
	return <TableActionIcon id={id} disabled={disabled} className="research-action-icon print" icon={<Printer size={size} />} onClick={onClick} toolTip={"Print"}/>
}

const DataIcon = ({onClick, size, disabled}) => {
	return <TableActionIcon disabled={disabled} className="research-action-icon xls" icon={<XlsIcon size={size} />} onClick={onClick} toolTip={"Download Model"}/>
}

const FullScreenIcon = ({onClick, size, disabled}) => {
	return <TableActionIcon disabled={disabled} className="research-action-icon full-screen" icon={<Maximize2 size={size} />} onClick={onClick} toolTip={"Fullscreen"}/>
}

const ResearchActionIcons = ({ fullWidth, size, setFullScreenResearch, downloadDisabled, printDisabled, dataDisabled, downloadOnClick, printOnclick, dataOnClick, hideFullscreen}) => {

	const printListener = (event) => {
		if (event.keyCode === 80 && (event.ctrlKey || event.metaKey) && !event.altKey && (!event.shiftKey || window.chrome || window.opera)) {
			event.preventDefault();
			document.getElementById("print-icon").click()
			if (event.stopImmediatePropagation) {
				event.stopImmediatePropagation();
			} else {
				event.stopPropagation();
			}
			return;
		}
	}

	useEffect(() => {
		document.addEventListener("keydown", printListener, true )

		return document.removeEventListener("keydown", (e) => printListener(e))
	}, [])
	return (
		<FlexWrapper className='research-actions-container' gap="m" size={size} wrap fullWidth={fullWidth}>
			<DownloadIcon size={size} disabled={downloadDisabled} onClick={ downloadDisabled ? null :  downloadOnClick} />
			{/* <PrintIcon size={size} disabled={printDisabled} id="print-icon" onClick={() => printDisabled ? null :  window.print()} /> */}
			<DataIcon size={size} disabled={dataDisabled} onClick={ dataDisabled ? null :  dataOnClick} />
			{hideFullscreen ? "" : <FullScreenIcon size={size} onClick={() => setFullScreenResearch(false)} />}
		</FlexWrapper>
	);
};

export default ResearchActionIcons;