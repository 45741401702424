import React, { useEffect, useRef, useState } from 'react'
import { components } from "react-select";
import './TopBar.scss'
import Popup from '../../components/popups/Popup';
import Logo from '../../components/misc/Logo'
import ProfileImage from '../../components/misc/ProfileImage';
import ProfileMenu from './ProfileMenu';
import { useNavigate } from 'react-router-dom';
import { getPortalSearchExtensionLabel, selectPortalSearchOption, getPortalSearchOptions, useStorage, watched, useWatchList, returnGroupingFromType, getPortalSearchLogo, getImageFromS3 } from '../../utils/Utils';
import AsyncSearchField from '../../components/inputs/AsyncSearchField';
import TickerSectorItem from '../../components/misc/TickerSectorItem';
import FlexWrapper from '../../components/misc/FlexWrapper';
import { AnalystDetails } from '../../components/cards/AnalystCard';
import { debounce } from 'throttle-debounce';

const TopBar = ({ changeRoute }) => {
	const [profileMenuOpen] = useState(false);
	const [fetchingResults, setFetchingResults] = useState(false)
	const [searchValue,] = useState('')
	const [tickers, setTickers] = useState('')
	const [loading, setLoading] = useState(true)
	const [searchText, setSearchText] = useState('')

	
	const [currentSearchIndex, setCurrentSearchIndex] = useState(0);
	const searchIndexRef = useRef();
	searchIndexRef.current = currentSearchIndex;
	
	const navigate = useNavigate();
	const { me, handleWatchListClick } = useWatchList()
	const storage = useStorage()
	const searchBarRef = useRef()

	useEffect(() => {
		storage.getOrFetch("/crm/tickers")
			.then((response) => setTickers(response))
			.then(() => setLoading(false));
	}, [])

	useEffect(() => {
	}, [loading, fetchingResults])

	const _loadSuggestions = (query, callback) => {
		const searchIndex = currentSearchIndex; 

		const handledCallback = (options) => {
			if (searchIndexRef.current > searchIndex) {
				return;
			}
			callback(options);
		}

		getPortalSearchOptions(query, setFetchingResults, tickers).then(handledCallback);
	}
	const loadSuggestions = debounce(500, _loadSuggestions);

	function renderTickerSectorItem(data, onClickAction) {
		let path =  data?.value?.groupingDetails?.urlSafeName && data?.value?.urlSafeName ? `/group/${data?.value?.groupingDetails?.urlSafeName}/${data?.value?.urlSafeName}` : null
		return (
			<TickerSectorItem
				grouping={returnGroupingFromType(data.type)}
				assetId={
					data.type === "ticker" ? data.value.ric :
						data.type === "sector" ? data.value.groupName : data.value.name
				}
				isMobile
				image={{"url": data.value.logo}}
				label={data.label}
				extensionLabel={getPortalSearchExtensionLabel(data)}
				onWatchlist={watched(me, data.type === "ticker" ? data.value.ric :
					data.type === "sector" ? data.value.groupName : data.value.name, path)}
				hideWatchlistIcon={data.type === "note"}
				onClick={onClickAction}
				path={path}
			/>
		);
	}

	const handleInputChange = (inputValue, action) => {
		if (action.action !== "input-blur" && action.action !== "menu-close") {
		 setSearchText(inputValue)
		 return inputValue
		}  
		return searchText
	};

	return (
		<div className="top-bar colour-bg-accent-2">
			<div className="top-bar-content">
				<div className="top-bar-left-container">
					<Logo className="top-bar-logo" onClick={() => navigate("/")} />
				</div>
				<div className="top-bar-right-container">
					<AsyncSearchField
						inputProps={{ name: "research-omnisearch", autoComplete: 'new-password', autoCorrect: 'off', spellCheck: 'off' }}
						disabled={loading}
						innerRef={searchBarRef}
						value={searchValue}
						openMenuOnClick={false}
						size="small"
						outlined
						placeholder="Search"
						onChange={(e) => { selectPortalSearchOption(e, navigate); searchBarRef.current.blur() }}
						loadOptions={loadSuggestions}
						onKeyDown={() => setCurrentSearchIndex(currentSearchIndex+1)}
						isLoading={fetchingResults || loading}
						onInputChange={handleInputChange}
						components={{
							Option: (props) => {
								return (
									<components.Option {...props}>
										<div
											className={"async-search-option" + (props.isFocused ? " is-focused" : "")}
											style={{ cursor: "pointer", border: "none" }}
											onClick={() => {
												props.innerProps.onClick(props.data);
											}}
										>
											{props.data.type === "analyst" ? (
												<AnalystDetails isMobile data={props.data.value} />
											) : props.data.type === "note"
												? props.data.value.template === "Sector Note"
													? renderTickerSectorItem(props.data, () => alert("sector click!"))
													: renderTickerSectorItem(props.data, () => alert("company click"))
												: renderTickerSectorItem(props.data, (me) => handleWatchListClick(me))
											}

										</div>
									</components.Option>
								);
							},
							NoOptionsMessage: () => <FlexWrapper fullWidth justify="center" className="p-l colour-text-neutral-dark-40">{searchText.length < 3 ? "Please enter 3 or more characters." : "No Results."}</FlexWrapper>
						}}
					/>
					<Popup
						// onClick={() => setProfileMenuOpen(!profileMenuOpen)}
						triggerElement={<ProfileImage focused={profileMenuOpen} user={me} />}
						popupElement={<ProfileMenu user={me} changeRoute={changeRoute} />}
						hoverOnly
					/>
				</div>
			</div>
		</div>
	);
};

export default TopBar;