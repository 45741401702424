import { Remote } from '@threeskye/global/dist/ThreeSkyeGlobal';
import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MobileTabs from '../../../components/buttons/MobileTabs';
import MobileResearchList from '../../../components/lists-and-tables/MobileResearchList';
import MobileTickerList from '../../../components/lists-and-tables/MobileTickerList';
import ResearchList from '../../../components/lists-and-tables/ResearchList';
import TickerList from '../../../components/lists-and-tables/TickerList';
import LoadingIcon from '../../../components/misc/LoadingIcon';
import DetailSectionHeader from '../../../components/shared/detail-section/DetailSectionHeader';
import NoResearchAnalystMessage from '../../../components/shared/prompts/NoResearchAnalystsMessage';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../../layout/grid/page/Page';
import PageGridItem from '../../../layout/grid/page/PageGridItem';
import useWindowDimensions, { analytics, useStorage, useRemote, goToResearch, useWatchList, useInfiniteScroll } from '../../../utils/Utils';
import AnalystGeneralSection from './AnalystGeneralSection';

const AnalystDetailsPage = () => {
	const [loading, setLoading] = useState(false)
	const [activeTab, setActiveTab] = useState("research");
	const [analyst, setAnalyst] = useState(null);
	const [coveredTickers, setCoveredTickers] = useState([]);
	const [loadingResearch, setLoadingResearch] = useState(true)
	const [research, setResearch] = useState([])
	const [moreResearchToLoad, setMoreResearchToLoad] = useState(true)

	const { width } = useWindowDimensions();
	const { me, handleWatchListClick } = useWatchList()
	const isMobile = width < 992;
	const storage = useStorage();
	const remote = useRemote();
	const NUM_OF_DOCS_TO_LOAD = 50

	const scrollableElementChildRef = useRef()
	const scrollableElementRef = useRef()

	const { analystId } = useParams();

	const { page } = analytics;


	const getQuery = (currentAnalyst = analyst, resetLength) => {
		var base = `/portal/library?analyst=${currentAnalyst.email}&num=${NUM_OF_DOCS_TO_LOAD}`;
		base += `&offset=${resetLength ? "0" : research.length}`;
		return base;
	}
	useEffect(() => {
		page()
		storage.getOrFetch("/portal/analysts")
			.then(a => {
				const me = a.find(i => i.name === analystId);
				setAnalyst(me);
				remote.get(getQuery(me, true)).then(resp => {
					setResearch(resp)
					setLoadingResearch(false)
					const distinctCompanies = resp.filter(n => n.template === "Company Note").map(n => n.coveredShortName).filter(uniqueArrayFilter);
					const distinctSectors = resp.filter(n => n.template === "Sector Note").map(n => n.coveredShortName).filter(uniqueArrayFilter);
					storage.getOrFetch("/crm/tickers").then(tickers => {
						const coveredCompanies = [];
						distinctCompanies.forEach(tickerFromList => {
							let ticker = tickers.find(t => t.ric === tickerFromList)
							if (ticker) coveredCompanies.push({ ...ticker, logo: { url: ticker.logo, ticker: ticker.ric }, grouping: "tickers" })
						});
						Promise.all(distinctSectors.map((sector) => remote.get(`/portal/group/sectors/${sector}/information`)))
							.then((responseArray) => {
								let coveredSectors = responseArray.map(res => {
									return { groupName: res.ticker, name: res.ticker, logo: { url: res.logo }, path: res.path, grouping: "sectors" };
								})
								setCoveredTickers([...coveredCompanies, ...coveredSectors]);
							})
						setCoveredTickers(coveredCompanies);
					})

				}).catch(() => {
					setLoadingResearch(false)
				});
			})
	}, [analystId])

	const uniqueArrayFilter = (value, index, array) => {
		return array.indexOf(value) === index;
	}

	const loadMorePublications = async () => {
		if (!moreResearchToLoad) {
			return;
		}
		await remote.get(getQuery()).then(resp => {
			let mapResp = resp.map(pub => {
				return { ...pub, publishedDate: pub.date }
			})
			setResearch(research.concat(mapResp))
			setMoreResearchToLoad(mapResp.length === NUM_OF_DOCS_TO_LOAD)
			setLoadingResearch(false)
		})
	}

	const [,] = useInfiniteScroll(loadMorePublications, scrollableElementChildRef, scrollableElementRef, true);

	const contentDivRef = useRef(null);
	
	const scrollToTop = () => {
		if (contentDivRef.current) {
			let element = contentDivRef.current
			const elementPosition = element.getBoundingClientRect().top;
			const offsetPosition = elementPosition - 100;
		
			window.scrollBy({
				 top: offsetPosition,
				 behavior: "smooth"
			});
		}
	};
	
	useEffect(() => {
		scrollToTop()
	}, [activeTab])
	

	return (
		<Page id={'analyst-page'} fixedBanner>
			{/* Modal */}
			{/* Page banner with back button */}
			<PageContentBanner divider wrap gap="l">
				{/* Analyst Info Details */}
				<AnalystGeneralSection analyst={analyst} />
			</PageContentBanner>
			{/* Page content */}
			<PageContentBodyGrid id="analyst-details-page" rowGap="xl" gridTemplateRows="1fr" splitScroll forwardRef={scrollableElementRef}>
				{/* grid container for the user details section */}
				{loading ? (
					<PageGridItem container col="1 / span 6">
						<LoadingIcon size={48} centered />
					</PageGridItem>
				) : (
					<>
						{/* Tables/Lists */}
						{/* Mobile UI */}
						{isMobile ? (
							<>
								{/* Mobile toggle */}
								<PageGridItem fullWidth style={{ position: "sticky", top: width >= 992 ? "-32px" : "56px", zIndex: 1 }}>
									<MobileTabs
										tabs={[
											{
												label: "Research",
												onClick: () => {
													setActiveTab("research");
												},
												active: activeTab === "research",
											},
											{
												label: "Coverage",
												onClick: () => {
													setActiveTab("companies");
												},
												active: activeTab === "companies",
											},
										]}
									/>
								</PageGridItem>
								{/* Published research for mobile */}
								{activeTab === "research" ? (
									!loadingResearch && research.length === 0 ? (
										<PageGridItem fullWidth>
											<NoResearchAnalystMessage />
										</PageGridItem>
									) : (
										<PageGridItem fullWidth>
											<MobileResearchList ref={contentDivRef} onClick={goToResearch} research={research} />
										</PageGridItem>
									)
								) : (
									<PageGridItem fullWidth>
										{/* Tickers that the analyst covers should go below */}
										<MobileTickerList ref={contentDivRef} onClick={handleWatchListClick} tickers={coveredTickers} me={me} />
									</PageGridItem>
								)}
							</>
						) : !loadingResearch && research.length === 0 ? (
							<PageGridItem fullWidth>
								<NoResearchAnalystMessage />
							</PageGridItem>
						) : (
							<>
								<PageGridItem container col="1 / span 8">
									<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
										<PageGridItem col="1 / span 6">
											<DetailSectionHeader header="Research" />
										</PageGridItem>
										{/* Research */}
										<PageGridItem col="1 / span 6">{research && <ResearchList forwardRef={scrollableElementChildRef} research={research} />}</PageGridItem>
									</PageContentBodyGrid>
								</PageGridItem>
								{/* Tickers/Companies */}
								<PageGridItem container col="9 / span 4">
									<PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid style={{ gridColumn: "1 / span 6", gridRow: 1 }} divider="left">
										<PageGridItem col="1 / span 6">
											<DetailSectionHeader header="Coverage" />
										</PageGridItem>
										<PageGridItem col="1 / span 6">
											<TickerList me={me} tickers={coveredTickers} onClick={handleWatchListClick} />
										</PageGridItem>
									</PageContentBodyGrid>
								</PageGridItem>
							</>
						)}
					</>
				)}
			</PageContentBodyGrid>
		</Page>
	);
}

export default AnalystDetailsPage;