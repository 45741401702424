
import React, { useEffect, useState } from "react";
import { ExternalLink } from "react-feather";
import useWindowDimensions, { getAnalystImage, getImage, useInfiniteScroll, useRemote, useStorage, watched, onDocumentClick, goToResearch, getAnalyst, analytics, downloadDocuments } from "../../../utils/Utils";
import TickerSectorItem from "../../../components/misc/TickerSectorItem";
import TableActionIcon from "../../../components/lists-and-tables/TableActionIcon";
import Divider from "../../../components/misc/Divider";
import XlsIcon from "../../../components/icons/XlsIcon";
import WatchlistIcon from "../../../components/icons/WatchlistIcon";
import MobileResearchList from "../../../components/lists-and-tables/MobileResearchList";
import Table from "../../../components/lists-and-tables/Table";
import { useNavigate } from "react-router-dom";
import { withLibraryDocsFilters } from "./withLibraryDocs";
import { format, isValid, parseISO } from "date-fns";
import ProfileImage from "../../../components/misc/ProfileImage";
import FlexWrapper from "../../../components/misc/FlexWrapper";
import { PageContentBodyGrid } from "../../../layout/grid/page/Page";
import PageGridItem from "../../../layout/grid/page/PageGridItem";
import LoadingIcon from "../../../components/misc/LoadingIcon";
import './LibraryTable.scss'
import BackToTop from "../../../components/lists-and-tables/BackToTop";
import Tooltip from "../../../components/popups/Tooltip";
import NoResultsMessage from "../../../components/shared/prompts/NoResultsMessage";


const LibraryTable = ({ libraryDocsFilters, hideImage }) => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([])
	const [tickers, setTickers] = useState([]);
	const [me, setMe] = useState({});
	const [analysts, setAnalysts] = useState([]);
	const [fetchingData, setFetchingData] = useState(false);
	const [noMoreResults, setNoMoreResults] = useState(false)

	const scrollableElementChildRef = React.useRef();
	const scrollableElementRef = React.useRef();
	const remote = useRemote();
	const NUM_OF_DOCS_TO_LOAD = 40;
	
	const [currentListIndex, setCurrentListIndex] = useState(1);
	const listIndexRef = React.useRef();
	listIndexRef.current = currentListIndex;	
	const storage = useStorage();

	const fetchData = async () => {
		if (fetchingData || noMoreResults) {
			setLoading(false);
			return;
		}
		setFetchingData(true);
		await remote.get(getQuery(data.length)).then(moreData => {
			setData(currentData => currentData.concat(moreData));
			setIsFetching(false);
			setFetchingData(false);
			if (moreData.length === 0) {
				setNoMoreResults(true)
			}
		})
	}

	const [isFetching, setIsFetching] = useInfiniteScroll(fetchData, scrollableElementChildRef, scrollableElementRef, true);

	const refreshWatched = (val) => {
		setMe(val);
		storage.put("/portal/me", val);
	}

	useEffect(() => {
		storage.getOrFetch("/crm/tickers").then(setTickers);
		storage.getOrFetch("/portal/me").then(setMe);
		storage.getOrFetch("/portal/analysts").then(setAnalysts);
		storage.watch("/portal/me", setMe)
		return storage.unwatch("/portal/me")
	}, [])

	const getQuery = (offset) => {
		var base = `/portal/library?num=${NUM_OF_DOCS_TO_LOAD}`;
		base += `&offset=${offset}`;
		if (libraryDocsFilters && libraryDocsFilters.ticker) {
			base += `&ticker=${encodeURIComponent(libraryDocsFilters.ticker)}`;
		}
		if (libraryDocsFilters && libraryDocsFilters.from && isValid(parseISO(libraryDocsFilters.from))) {
			base += `&from=${format(parseISO(libraryDocsFilters.from), 'yyyy-MM-dd')}`;
		}
		if (libraryDocsFilters && libraryDocsFilters.to && isValid(parseISO(libraryDocsFilters.to))) {
			base += `&to=${format(parseISO(libraryDocsFilters.to), 'yyyy-MM-dd')}`;
		}
		
		return base;
	}

	useEffect(() => {
		setNoMoreResults(false)
		setFetchingData(true);
		setData([]);
		setLoading(true);
		const thisSend = currentListIndex+1;
		setCurrentListIndex(thisSend);
		remote.get(getQuery(0)).then(d => {
			if(thisSend !== listIndexRef.current){
				return; 
			}
			setData(d);
			setLoading(false);
			setFetchingData(false);
		});
	}, [libraryDocsFilters])



	const colWidths = [15 + "%", 15 + "%", 45 + "%", 15 + "%", 15 + "%"];
	const headers = ["Date", "Coverage", "Title", "Analyst", ""];
	const { width, height } = useWindowDimensions();
	const navigate = useNavigate()
	const dataMap = data.map((document, idx) => {
		const defaultPdf = (document && document.files && document.files.filter(f => f.defaultPdf)) || [];
		const analyst = getAnalyst(analysts, document)
		const unread = !document.read;
		const analystName = document.analystName

		return (
			<tr
				key={idx}
				onClick={() => goToResearch(document, navigate)}
				className={`cursor-pointer${unread ? " unread-research" : ""}`}
			>
				<td>
					<p>{document && document.publishedDate && format(parseISO(document.publishedDate), "dd/MM/yyyy")}</p>
				</td>
				<td onClick={(e) => {
					e.stopPropagation()
					navigate(document.path)
				}}>
					<TickerSectorItem
						unread={unread}
						label={document.coveredShortName}
						image={document.imageUrl}
					/>
				</td>
				<td style={{ paddingRight: "5.5rem" }}>
					<p className="research-title">{document.title}</p>
				</td>

				{analyst && analyst.isAuthor
					? <>
						<td onClick={(e) => { e.stopPropagation(); if (analyst && analyst.isAuthor) navigate("/analysts/" + analyst.name) }} >
							<FlexWrapper align="center" gap="s" >
								<ProfileImage src={analyst && analyst.photo} name={analystName} />
								{analystName}
							</FlexWrapper>
						</td>
					</>
					: <>
						<td style={{ cursor: 'default' }} onClick={(e) => { e.stopPropagation() }} >
							<FlexWrapper align="center">
								<Tooltip label="This analyst is no longer available.">
									<FlexWrapper align="center" gap="s" >
										<ProfileImage style={{ cursor: 'default' }} src={analyst && analyst.photo} name={analystName} />
										{analystName}
									</FlexWrapper>
								</Tooltip>
							</FlexWrapper>
						</td>
					</>
				}
				<td className="table-action-icons">
					<TableActionIcon
						onClick={(e) => {
							downloadDocuments(e, defaultPdf)
							analytics.track("downloadPDF", { asset: document.coveredShortName, publication: document })
						}}
						icon={<ExternalLink width="24px" />}
						toolTip={defaultPdf.length === 0 ? "No PDF" : "Open PDF"}
					/>
					<Divider vertical style={{ height: 24, display: "inline-flex" }} spacingRight="m" />
					<TableActionIcon
						disabled={document?.models?.length === 0}
						onClick={(e) => {
							downloadDocuments(e, document.models, true)
							analytics.track("downloadData", { asset: document.coveredShortName, publication: document })
						}}
						icon={<XlsIcon />}
						toolTip={document && document.models && document.models.length >= 1 ? `Model (${document?.models?.length})` : "No Model"}
					/>
					<Divider vertical style={{ height: 24, display: "inline-flex" }} spacingRight="m" />
					<WatchlistIcon size={24} path={document.path} assetId={document.coveredShortName} onWatchList={watched(me, document.coveredShortName, document.path)} onClick={(watched) => refreshWatched(watched)} grouping={document.template === "Sector Note" ? "sectors" : "tickers"} />
				</td>
			</tr >
		);
	});

	return (
		<PageContentBodyGrid forwardRef={scrollableElementRef} >
			<PageGridItem colSpan="12">
				{loading && !noMoreResults
					? <LoadingIcon centered={true} />
					:
					<div ref={scrollableElementChildRef} >
						{(width > 768) && (height > 490)
							? data && data.length > 0 
								? <Table id="top" className="research-table" minWidth={1000} colWidths={colWidths} headers={headers} dataMap={dataMap} />
								: <NoResultsMessage subMessage="Please try changing your search criteria." noIcon />
							: <MobileResearchList research={data} onClick={goToResearch} hideImage={hideImage} />
						}
					</div>
				}
				{noMoreResults
					? <BackToTop href="#top" />
					: isFetching && <LoadingIcon centered={true} />}
			</PageGridItem>
		</PageContentBodyGrid>


	)
}

export default withLibraryDocsFilters(LibraryTable);
