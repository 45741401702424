import React from 'react';
import FlexWrapper from '../../../components/misc/FlexWrapper';
import TickerSectorItem from '../../../components/misc/TickerSectorItem';
import { useWatchList } from '../../../utils/Utils';
import PageGridItem from '../../../layout/grid/page/PageGridItem';
import DetailCard from '../../../components/cards/DetailCard';
import Divider from '../../../components/misc/Divider';

// Sub labels under the sector name
const SectorSubLabels = ({ sector }) => {
	return (
		<FlexWrapper fullWidth gap="s">
			<span>{sector.region ? sector.region : "-"}</span>
			<Divider vertical height={10} />
			<span>{sector.companies ? (sector.companies.length + " Companies") : "-"}</span>
		</FlexWrapper>
	);
}

const SectorPageDetailsCard = ({ editMode, sector, compact }) => {
	const { me, handleWatchListClick } = useWatchList()

	const watched = me && me.watched && me.watched.group && me.watched.group.indexOf(sector.watch) >= 0;

	return (
		<DetailCard id="sector-page-detail-card">
			<PageGridItem col="1 / span 12">
				<TickerSectorItem
					size="xl"
					onWatchlist={watched}
					label={sector.label}
					extensionLabel={<SectorSubLabels sector={sector} />}
					image={sector && sector.label && {url: sector.logo}}
					onClick={(me) =>{handleWatchListClick(me)} }
					grouping="sectors"
					assetId={sector.label}
					path={sector.path}
				/>
			</PageGridItem>
		</DetailCard>
	);
}

export default SectorPageDetailsCard;