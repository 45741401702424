import React from "react";
import { X } from "react-feather";
import Select from "react-select";
import "./SearchField.scss";

const SearchField = ({ components, classNames, autoFocus, icon, size, minWidth, width, options, onClear, innerRef, openMenuOnFocus, ...otherProps }) => {
	const mainClassName = "search-select-field";
	let className = [mainClassName, classNames].filter((cn) => !!cn).join(" ");

	if (icon) {
		className += " search-field-icon";
	}
	if (size) {
		className += " search-field-size-" + size;
	}
	if (width) {
		className += " search-field-width-" + width;
	}
	if (minWidth) {
		className += " search-field-min-width";
	}

	return <Select
		{...otherProps}
		isMulti={false}
		components={{ ...components, DropdownIndicator: () => null, IndicatorSeparator: () => null, ClearIndicator: () => <X onClick={onClear && onClear} onTouchEnd={onClear && onClear} /> }}
		cacheOptions
		className={className}
		classNamePrefix={mainClassName}
		autoFocus={autoFocus}
		options={options}
		ref={innerRef}
		menuPosition="fixed"
		openMenuOnFocus={openMenuOnFocus}
		menuPortalTarget={document.body} 
		styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
	/>
		;

}

export default SearchField;
