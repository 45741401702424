import React, { useEffect, useState } from 'react';
import Card from './Card';
import "./AnalystCard.scss";
import ProfileImage from '../misc/ProfileImage';
import FlexWrapper from '../misc/FlexWrapper';
import { useNavigate } from 'react-router-dom';
import { useStorage, useWatchList } from '../../utils/Utils';
import WatchlistIcon from '../icons/WatchlistIcon';
import ExtensionLabel from '../misc/ExtensionLabel';


export const AnalystDetails = ({ data, isMobile, onWatchlist, watchlistLayout, onWatchListClick }) => {
	const { title, name, photo, email } = data;
	const { me, handleWatchListClick } = useWatchList()

	return (
		<FlexWrapper wrap gap="m" fullWidth={isMobile}>
			{/* Image */}
			<ProfileImage noHover src={photo ? photo : ""} size={(isMobile || watchlistLayout) ? 40 : 48} user={data} />
			{/* Details */}
			<FlexWrapper wrap direction="column" align="flex-start" justify="center" flex="1 1" gap="s">
				{isMobile || watchlistLayout ? <h4 className="font-weight-regular">{name}</h4> : <h2>{name}</h2>}
				<ExtensionLabel style={{marginLeft: 0}}>{title ? title : "-"}</ExtensionLabel>
			</FlexWrapper>
			{watchlistLayout && <WatchlistIcon onWatchList={onWatchlist} assetId={email} onClick={onWatchListClick} grouping={"analysts"} />}
		</FlexWrapper>
	);
};

const AnalystCard = ({ data, onWatchlist, watchlistLayout, isMobile, onWatchListClick }) => {
	const navigate = useNavigate()
	return (
		<Card className="analyst-card" onClick={() => navigate("/analysts/" + data.name)}>
			<AnalystDetails data={data} onWatchlist={onWatchlist} isMobile={isMobile} watchlistLayout={watchlistLayout} onWatchListClick={onWatchListClick} />
		</Card>
   );
}

export default AnalystCard;