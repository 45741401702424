// Documentation ----> https://popper.js.org/
import React from 'react';
import './PopupMenuItem.scss';

const PopupMenuItem = (props) => {
    const { label, icon, children, onClick, smallText, className, noRadius } = props;

	return (
		<div className={`popup-menu-item-wrapper${smallText ? " popup-menu-item-small" : ""}${noRadius ? " popup-menu-item-no-radius" : ""}${className ? " " + className : ""}`} onClick={onClick}>
			{icon && <div className="popup-menu-item-icon-wrapper">{icon}</div> }
			{label ? label : children }
		</div>
	);
};

export default PopupMenuItem;