import React from 'react';
import FlexWrapper from '../../misc/FlexWrapper';

const EmptyStateMessage = ({icon, header, subHeader, children}) => {
	return (
		<FlexWrapper align="center" direction="column" fullWidth gap="xl" className="pt-l pb-l">
			{icon && (
				<FlexWrapper justify="center" align="center" className="colour-bg-warning-light p-m" style={{ borderRadius: "100%" }}>
					{React.cloneElement(icon, { size: 24, className: "colour-text-warning-dark" })}
				</FlexWrapper>
			)}
			<FlexWrapper gap="m" direction="column" fullWidth style={{maxWidth: 600}}>
				<h4 className="text-align-center font-weight-semi-bold">{header}</h4>
				{subHeader && <p className="m-none p-large text-align-center font-size-body-s">{subHeader}</p>}
			</FlexWrapper>
			{children}
		</FlexWrapper>
	);
}

export default EmptyStateMessage;