import React, { useState, useRef, useMemo, useCallback, useEffect } from "react";
//import Editor from '@threeskye/editor-component-slate';
import './SlateEditor.scss';
import { createEditor, Descendant } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { getStyle, getTableStyles } from "./DataController";
import { CellElement, ImageElement, ImageWrapperElement, SideBySideElement, TableElement, TableWrapperElement } from "./SlateUtils";


const SlateEditor = (props) => {

	const initial = props.load;

	const tableStyleRef = useRef();
	const divRef = useRef();
	const [value, setValue] = useState(initial);
	const [copiedTableStyles, setCopiedTableStyles] = useState(null);

	const id = props.id || 'id' + Math.floor(Math.random() * Math.floor(999999))

	const stylesMap = {
		p: "p[data-slate-node=element]",
		li: "li[data-slate-node=element]",
		h1: "h1",
		h2: "h2",
		h3: "h3",
		td: "div.table-cell",
		table: "table.table",
		th: "th",
	};

	const convertStyleToHyphen = (element) => {
		if (element === 'tableFooter') return 'table-footer';
		if (element === 'tableHeader') return 'table-header';
		if (element === 'imageHeader') return 'image-header';
		if (element === 'imageFooter') return 'image-footer';
		if (element === 'td') return 'table-cell';

		return element;
	}
	const { styles, styleDefs } = props;

	const styleToCss = (styleObj) => {
		const parser = createParser(/[A-Z]/, match => `-${match.toLowerCase()}`);
		if (!styleObj || typeof styleObj !== 'object' || Array.isArray(styleObj)) {
			throw new TypeError(`expected an argument of type object, but got ${typeof styleObj}`);
		}
		const lines = Object.keys(styleObj).map(property => `${parser(property)}: ${styleObj[property]};`);
		return lines.join('\n');
	}

	const createParser = (matcher, replacer) => {
		const regex = RegExp(matcher, 'g');
		return string => {
			// * throw an error if not a string
			if (typeof string !== 'string') {
				throw new TypeError(`expected an argument of type string, but got ${typeof styleObj}`);
			}

			// * if no match between string and matcher
			if (!string.match(regex)) {
				return string;
			}

			// * executes the replacer function for each match
			// ? replacer can take any arguments valid for String.prototype.replace
			return string.replace(regex, replacer);
		};
	}

	const myStyles = Object.keys(styleDefs).map(key => {
		const actual = styles[styleDefs[key]];
		const converted = getStyle(actual);
		converted.lineHeight = "1.2";
		const val = {};
		val[key] = converted;
		return val;
	});

	const flattenedStyles = {};
	myStyles.forEach(style => Object.assign(flattenedStyles, style));

	const tableStyles = styleDefs.tableStyles;

	const flowingConfig = props.flowingConfig || props.staticConfig || {};

	useEffect(() => {
		const styles = getTableStyles(props.styles, flowingConfig.rowStyleOptions, flowingConfig.columnStyleOptions, props.data);
		if(styles) {
			setCopiedTableStyles(styles);
		}
	}, [])

	const defaultCellStyle = getStyle(styles[styleDefs.cell], true);
	const cellHeaderStyle = getStyle(styles[styleDefs.cellHeader], true);

	const size = { width: 595, "height": 842 };	//FIXME hardcoded arrgh!!

	const tableWrapperStyles = {};
	if (flowingConfig && flowingConfig.fullWidthTableMargins) {
		const marginLeft = flowingConfig.fullWidthTableMargins.left;
		const marginRight = flowingConfig.fullWidthTableMargins.right;
		const margins = flowingConfig.margins;

		tableWrapperStyles["page-width"] = {
			"position": "relative",
			"left": (marginLeft - margins.left) + 'pt',
			"width": (size.width - marginLeft - marginRight) + 'pt',
			"background": "white"
		};

		tableWrapperStyles["full-width"] = {
			"position": "relative",
			"left": (marginLeft - margins.left) + 'pt',
			"width": (size.width - marginLeft - marginRight) + 'pt',
			"background": "white"
		};
		tableWrapperStyles["table-border"] = {
			"border": "1px solid #004271"
		};
		tableWrapperStyles["full-width-table-border"] = {
			"position": "relative",
			"left": (marginLeft - margins.left) + 'pt',
			"width": (size.width - marginLeft - marginRight) + 'pt',
			"background": "white",
			"border": "1px solid #004271"
		};
		tableWrapperStyles["image-border"] = {
			"border-top": "1.25pt solid #C1CBDE",
			"border-bottom": "1.25pt solid #C1CBDE",
			"padding-top": "5pt",
			"margin-bottom": "3pt"
		};

		//FIXME why are these hardcoded? Should be read from template
		tableWrapperStyles["text-width-table-border"] = {
			"border": "1px solid #004271"
		};
		tableWrapperStyles["text-width-image-border"] = {
			"border-top": "1.25pt solid #C1CBDE",
			"border-bottom": "1.25pt solid #C1CBDE",
			"padding-top": "5pt",
			"padding-bottom": "5pt"
		};

		//FIXME where do these border settings come from?  Template somewhere?
		tableWrapperStyles["page-width-with-border"] = {
			"position": "relative",
			"left": (marginLeft - margins.left) + 'pt',
			"width": (size.width - marginLeft - marginRight) + 'pt',
			"background": "white",
			"border-top": "1.25pt solid rgb(193, 203, 222)",
			"border-bottom": "1.25pt solid rgb(193, 203, 222)",
			"padding-top": "5pt",
			"margin-bottom": "3pt"
		}
	}


	//browser default cellHeader is text-align: center... kill this
	if (cellHeaderStyle && !cellHeaderStyle.hasOwnProperty("textAlign")) {
		cellHeaderStyle.textAlign = "left";
	}

	const tdStyle = { backgroundColor: "#fff" };
	const thStyle = {};

	// const paddingBelowTable = config.paddingBelowTable;
	// if (paddingBelowTable) {
	// 	flattenedStyles.table = {
	// 		marginBottom: paddingBelowTable+"pt"
	// 	}
	// } else {
	// 	flattenedStyles.table = {
	// 		marginBottom: tableStyles.cellVerticalPadding + "pt"
	// 	}
	// }

	Object.assign(tdStyle, defaultCellStyle);
	flattenedStyles.td = tdStyle;
	Object.assign(thStyle, cellHeaderStyle);
	flattenedStyles.th = thStyle;

	let injectedStyles = Object.keys(flattenedStyles).reduce((collector, element) => {
		const target = stylesMap[element];
		const hyphenedElement = convertStyleToHyphen(element);
		if (!target || typeof target !== "Array") {
			collector += `#${id} ${target || ("." + hyphenedElement)} {${styleToCss(flattenedStyles[element])}} `;
		} else {
			target.forEach(aTarget => {
				collector += `#${id} ${aTarget} {${styleToCss(flattenedStyles[element])}} `;
			});
		}
		return collector;
	}, "");

	//"Exceptions to the rule"
	if (myStyles["li"] && myStyles["li"].indent) {
		injectedStyles += "ul[data-slate-node=element] {padding-left: " + myStyles["li"].indent + "pt} ";
	}
	//FIXME TODO CSJM hack 20220818
	injectedStyles += `#{id} img {width:100%}`;

	const styleInterpreter = (x) => {
		if (x) {
			if (copiedTableStyles && copiedTableStyles[x]) {
				return copiedTableStyles[x];
			} else if (tableWrapperStyles && tableWrapperStyles[x]) {
				return tableWrapperStyles[x];
			} else {
			}
		}
		return null;
	}

	const style = {};

	const renderElement = ({ attributes, children, element, style, ...others }) => {
		switch (element.type) {
			case 'list-item':
				return <li {...attributes}>{children}</li>
			case 'numbered-list':
				return <ol {...attributes}>{children}</ol>
			case 'bulleted-list':
				return <ul {...attributes}>{children}</ul>
			case 'heading-one':
				return <h1 {...attributes}>{children}</h1>
			case 'heading-two':
				return <h2 {...attributes}>{children}</h2>
			case 'heading-three':
				return <h3 {...attributes}>{children}</h3>
			case "image":
				return <ImageElement styleInterpreter={styleInterpreter} attributes={attributes} children={children} element={element} />
			case "image-wrapper":
				return <ImageWrapperElement style={element.style} styleInterpreter={styleInterpreter} {...attributes} children={children} />
			case "side-by-side":
				return <SideBySideElement style={element.style} styleInterpreter={styleInterpreter} attributes={attributes} children={children} element={element} />
			case "image-header":
				return <div className="image-header" {...attributes}>{children}</div>
			case "image-footer":
				//if footer is empty don't render it
				if(children && children.length === 1 && children[0].props.text.text === "") {
					return null;
				}
				return <div className="image-footer" {...attributes}>{children}</div>
			case 'table-wrapper': return <TableWrapperElement style={element.style} styleInterpreter={styleInterpreter} {...attributes} children={children} />
			case 'table-header': return <div className="table-header" {...attributes}>{children}</div>
			case 'table-footer': return <div className="table-footer" {...attributes}>{children}</div>
			case 'table': return <TableElement styleInterpreter={styleInterpreter} attributes={attributes} children={children} element={element} />
			case 'table-cell': return <CellElement styleInterpreter={styleInterpreter} style={style} attributes={attributes} children={children} element={element} />
			default:
				return <p {...attributes}>{children}</p>
		}
	}

	const renderLeaf = useCallback(({ attributes, children, leaf }) => {
		let className = "";
		if (leaf.bold) {
			className += " bold "
		}
		if (leaf.italic) {
			className += " italic "
		}
		if (leaf.underline) {
			className += " underline "
		}
		return (
			<span
				{...attributes}
				className={className}
			>
				{children}
			</span>
		)
	}, [])


	const editor = useMemo(() => withReact(createEditor()), [])
	return (
		<div ref={divRef} id={id}>
			<style>
				{injectedStyles}
			</style>
			<Slate editor={editor} value={initial} >
				<Editable readOnly placeholder="" renderElement={renderElement} renderLeaf={renderLeaf} />
			</Slate>
		</div>

	)
}


export default SlateEditor;