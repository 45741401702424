import React, { Component, useEffect, useState } from "react";
import { useRemote } from "../../utils/Utils";

const CategoryChart = ({styles, dto, value, config, template})  => {

    const [imageData, setImageData] = useState(null);

    let { width, height } = config;

    const remote = useRemote();
    
    width = width + "pt";
    height = height + "pt";

    useEffect(() => {

        const ssrDTO = {
            data: {
                data: dto.data
            },
            configuration: JSON.stringify(config),
            className: "CategoryChart",
            dataSuffix: null 
        }   //TODO fix datasuffix dto.tickers && dto.tickers.length > 1 ? dataSuffix : null
        remote.post("/ssrender/" + dto.template, ssrDTO).then(resp=>{
            setImageData(resp.data);
        });

    }, [config, dto])

    if (!imageData) return null;

    return <img style={{width, height}} src={"data:image/png;base64," + imageData} />

}

export default CategoryChart;
