import { useEffect, useState, useRef } from "react";
import Page, { PageContentBodyGrid } from "../../../layout/grid/page/Page";
import { useParams } from "react-router-dom";
import PageGridItem from "../../../layout/grid/page/PageGridItem";
import useWindowDimensions, { useRemote, analytics, useStorage, useInfiniteScroll, useWatchList, watched, downloadDocuments } from "../../../utils/Utils";
import TickerPageDetailsCard from './TickerPageDetailsCard'
import TickerResearchCard from "./TickerResearchCard";
import { useNavigate } from "react-router-dom";
import ResearchOverlay from "../../../components/misc/ResearchOverlay";
import ResearchViewer from "../../../components/shared/research/ResearchViewer";
import ResearchSummary from "../../../components/shared/research/ResearchSummary";
import ResearchTable from "../../../components/shared/research/ResearchTable";
import NoResearchMessage from "../../../components/shared/prompts/NoResearchMessage";
import CompanyHeaderCards from "./CompanyHeaderCards";

const CompanyPage = ({ documentViewerOnly }) => {

	const [company, setCompany] = useState({});
	const [publications, setPublications] = useState([]);
	const [loadingPublications, setLoadingPublications] = useState(false);
	const [morePublications, setMorePublications] = useState(true);
	const [fullscreenResearch, setFullScreenResearch] = useState(false);
	const [selectedResearch, setSelectedResearch] = useState(null)
	const [extraInfo, setExtraInfo] = useState(null);
	const [analysts, setAnalysts] = useState([]);
	const [compactView, setCompactView] = useState(true);
	const [researchSelectedFromSummaryPage, setResearchSelectedFromSummaryPage] = useState(0)
	const [extraInfoLoading, setExtraInfoLoading] = useState(true);
	const [currentRating, setCurrentRating] = useState("");
	const [targetPrice, setTargetPrice] = useState("");
	const [editMode,] = useState(false);

	const params = useParams();
	const ticker = params.ticker;
	const publication = params.publication;
	const remote = useRemote();
	const navigate = useNavigate()
	const storage = useStorage();
	const NUM_OF_DOCS_TO_LOAD = 50
	const { me, handleWatchListClick } = useWatchList()

	const scrollableElementChildRef = useRef()
	const scrollableElementRef = useRef()

	useEffect(() => {
		if (company && publications) {
			selectedResearch
				? document.title = company && company.ticker && company.ticker.ric && selectedResearch.title ? company.ticker.ric + " | " + selectedResearch.title : "Client Portal | Craigs Investment Partners"
				: document.title = company && company.ticker && company.ticker.ric ? company.ticker.ric + " | Craigs Investment Partners" : "Client Portal | Craigs Investment Partners"
		}
	}, [company, selectedResearch, publications])

	useEffect(() => {
		return () => document.title = "Client Portal | Craigs Investment Partners"
	}, [])

	useEffect(() => {
		if (ticker) {
			remote.get("/portal/ticker/" + ticker).then(dto => {
				if (dto && dto.ticker) {
					setCompany(dto)
					if (dto && dto.savedData) {
						dto.savedData.forEach(s => {
							if (s.field === 'Recommendation') {
								setCurrentRating(s.stringValue);
							}
							if (s.field === 'Target Price') {
								setTargetPrice(s.stringValue);
							}
						});
					}
				} else {
					navigate("/page-not-found")
				}
			}).catch(() => {
				navigate("/page-not-found")
			})
				;
			remote.get("/portal/ticker/" + ticker + "/information")
				.then(res => {
					setExtraInfo(res)
					setExtraInfoLoading(false)
				})
				.catch(() => {
					setExtraInfo({ generalInformation: "", financialInformation: "" })
					setExtraInfoLoading(false)
				});
			storage.getOrFetch('/portal/analysts').then(setAnalysts);
			analytics.page()
			loadMorePublications(true)
			if (!params || !params.publication) {
				setSelectedResearch(null)
			}
		}
	}, [ticker])

	useEffect(() => {
		if (!params.publication) {
			setSelectedResearch(null)
		} else if (publications) {
			let publication = publications.find(doc => doc && doc.publicationId && doc.publicationId.toString() === params.publication)
			if (publication) {
				setSelectedResearch(publication)
			} else {
				setSelectedResearch({ publicationId: parseInt(params.publication) })
			}
		}
		setFullScreenResearch(false)
	}, [params, publication])

	const getQuery = () => {
		let existingResearchList = [...publications.filter(p => p.ticker === ticker)]
		var base = `/portal/library?num=${NUM_OF_DOCS_TO_LOAD}`;
		base += `&offset=${existingResearchList.length}`;
		base += `&ticker=${encodeURIComponent(ticker)}`;
		return base;
	}

	const loadMorePublications = async (initialLoad) => {
		if ((loadingPublications || !morePublications) && !initialLoad) {
			return;
		}

		setLoadingPublications(true);
		await remote.get(getQuery()).then(p => {
			const pubList = p.map((pub) => {
				return {
					analystName: pub.analystName,
					publicationId: pub.publicationId,
					publishedDate: pub.publishedDate,
					templateName: pub.template,
					ticker: pub.coveredShortName,
					title: pub.title,
					files: pub.files,
					read: pub.read,
					models: pub.models,
					hasHtmlVersion: pub.hasHtmlVersion
				}
			})
			if (pubList.length > 0 && pubList[0].ticker !== ticker) return

			let existingResearchList = [...publications.filter(p => p.ticker === ticker)]
			setPublications(() => existingResearchList.concat(pubList));
			setMorePublications(pubList.length === NUM_OF_DOCS_TO_LOAD);
			setLoadingPublications(false);
			if (params.publication && initialLoad) {
				let publication = pubList.find(doc => doc && doc.publicationId && doc.publicationId.toString() === params.publication)
				if (publication) {
					setSelectedResearch(publication)
					analytics.track("viewResearch", { id: publication && publication.publicationId, ticker: publication.coveredShortName })
				} else {
					setSelectedResearch({ publicationId: parseInt(params.publication) })
				}
			}
		})
	}

	const [,] = useInfiniteScroll(loadMorePublications, scrollableElementChildRef, scrollableElementRef, true);

	const handleResearchSelect = (publication) => {

		var ua = navigator.userAgent.toLowerCase();
		var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
		const defaultPdf = (publication && publication.files && publication.files.filter(f => f.defaultPdf)) || []

		if (isAndroid && publication && !publication.hasHtmlVersion && publication.hasHtmlVersion !== undefined) {
			analytics.track("downloadPDF", { asset: publication.coveredShortName, publication });
			downloadDocuments(null, defaultPdf)
			return;
		}

		setSelectedResearch(defaultPdf)
		if (publication) {
			analytics.track("viewResearch", { id: publication.publicationId, ticker: publication.ticker })
			navigate('/company/' + ticker + '/' + publication.publicationId);
		} else {
			analytics.track("viewResearchList", { ticker: company.ticker })
			navigate('/company/' + ticker);
		}

		setResearchSelectedFromSummaryPage(researchSelectedFromSummaryPage + 1)
	}

	const coveringAnalysts = analysts && company && company.coveringAnalysts && (company.coveringAnalysts
		.map(a => {
			return analysts.find(s => s.name === a);
		}) || [])
		.sort((a, b) => {
			if (!a && !b) return 0
			if (!a) return -1
			if (!b) return 1

			return a.sortOrder - b.sortOrder
		});

	const noResearchPublished = !loadingPublications && publications.length === 0;

	return (
		<Page noBanner>
			{fullscreenResearch && <ResearchOverlay documentViewerOnly={documentViewerOnly} extraInfo={extraInfo} ticker={{ ...company.ticker, targetPrice, currentRating }} setFullScreenResearch={setFullScreenResearch} publication={selectedResearch} analysts={analysts} />}
			{/* Page content */}
			<PageContentBodyGrid className={'padding-control'} id="ticker-page" rowGap={compactView ? "m" : "xl"} gridTemplateRows="1fr" forwardRef={scrollableElementRef} >
				{!documentViewerOnly && 
					<CompanyHeaderCards
						company={company}
						extraInfo={extraInfo}
						setCompactView={setCompactView}
						compactView={compactView}
						extraInfoLoading={extraInfoLoading}
						noResearchPublished={noResearchPublished}
						editMode={editMode}
						targetPrice={targetPrice}
						currentRating={currentRating}
						coveringAnalysts={coveringAnalysts}
					/>
				}
				{/* displays research or summary of company and list of research  */}
				{noResearchPublished && !documentViewerOnly
					? <PageGridItem fullWidth>
						<NoResearchMessage noWatchList onClick={(me) => handleWatchListClick(me)} onWatchlist={watched(me, params.ticker)} assetId={params.ticker} />
					</PageGridItem>
					: selectedResearch || documentViewerOnly
						? <ResearchViewer
							analysts={analysts}
							compact={compactView}
							publication={selectedResearch}
							setSelectedResearch={handleResearchSelect}
							setFullScreenResearch={setFullScreenResearch}
							fullScreenResearch={fullscreenResearch}
							documentViewerOnly={documentViewerOnly}
						/>
						: <ResearchTable
							compact={compactView}
							publications={publications}
							setSelectedResearch={handleResearchSelect}
							morePublications={morePublications}
							loadMore={loadMorePublications}
							loadingPublications={loadingPublications}
							forwardRef={scrollableElementChildRef}
							hideImage={true}
							hideWatchlistIcon
						/>
				}
			</PageContentBodyGrid>
		</Page>
	);
}

export default CompanyPage