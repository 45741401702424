import React from 'react';
import TextArea from '../../inputs/TextArea';
import TextInput from '../../inputs/TextInput';
import DetailLabel from './DetailLabel';

const TextOrLink = (props) => {
	return (
		<>{props.link ? <a {...props} href={props.link} target="_blank">{props.children}</a> : <p {...props}>{props.children}</p> }</>	
   );
}

const DetailField = (props) => {
	const { label, value, editMode, onChange, autoFocus, invalid, helperText, disabled, textArea, type, placeholder, bold, variant, className, valueClassName, link } = props;
	return (
		<div className={`detail-field-wrapper${className ? " " + className : ""}`}>
			{editMode ? (
				<React.Fragment>
					{textArea ? (
						<TextArea {...props} value={value} className="transition-fade-up-slow">
							{value}
						</TextArea>
					) : (
						<TextInput
							disabled={disabled}
							invalid={invalid}
							helperText={helperText}
							autoFocus={autoFocus}
							label={label}
							placeholder={placeholder || label}
							value={value}
							onChange={onChange}
							className="transition-fade-up-slow"
							type={type}
							variant={variant}
						/>
					)}
				</React.Fragment>
			) : (
				<React.Fragment>
					{label ? <DetailLabel label={label} /> : ""}
					<TextOrLink
						link={link}
						className={`colour-text-neutral-dark ${ link && "text-link-block"} text-decoration-none mb-none transition-fade-up-slow${label ? " mt-s " : ""}${bold ? " font-weight-semi-bold" : ""}${
							valueClassName ? " " + valueClassName : ""
						}`}
						style={{ wordBreak: "break-word" }}
					>
						{value ? value : "-"}
					</TextOrLink>
					{/* <p className={`mb-none transition-fade-up-slow${label ?  " mt-s " : ""}${bold ? " font-weight-semi-bold" : ""}${valueClassName ? " " + valueClassName : ""}`} style={{ wordBreak: "break-word" }}>
						{value ? value : "-"}
					</p> */}
				</React.Fragment>
			)}
		</div>
	);
}

export default DetailField;